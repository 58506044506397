import React, { FC, useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import isEqual from 'lodash.isequal';

import { DirectorFormProps } from '@interface/company.interface';
import CompanyDirectorDetailView from '@component/CompanyDirectorDetailView';
import {
  addCompanyDirector,
  updateCompanyDirector,
} from '@action/company.action';
import { useAppDispatch } from '@hook/hooks.hook';
import LoadingButton from '@component/LoadingButton';

import PersonalInformationStep from './steps/PersonalInformationStep';
import IdentificationStep from './steps/IdentificationStep';
import ContactInformationStep from './steps/ContactInformationStep';
import AddressInformationStep from './steps/AddressInformationStep';
import { getInitialValues, validationSchema } from './validation';

const steps = [
  'Personal Information',
  'Identification',
  'Address Information',
  'Contact Information',
];

const DirectorDrawerForm: FC<DirectorFormProps> = ({
  open,
  onClose,
  director: initialDirector,
}) => {
  const [director, setDirector] = useState(initialDirector);
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const initialValues = getInitialValues(director);

  const handleNext = useCallback(
    async (values: any) => {
      setIsSubmitting(true);
      const action = director?.id ? updateCompanyDirector : addCompanyDirector;
      const noChange = isEqual(values, initialValues);

      const res = noChange ? {} : ((await dispatch(action(values))) as any);

      if (!res.error) {
        if (activeStep !== steps.length - 1) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          if (res.payload)
            setDirector((oldDirector) => ({
              ...oldDirector,
              ...res.payload,
            }));
        } else {
          onClose();
        }
      }

      setIsSubmitting(false);
    },
    [activeStep, director, dispatch, initialValues, onClose],
  );

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step: number) => {
    const props = { isReadonly: Boolean(director?.isVerified) };

    switch (step) {
      case 0:
        return <PersonalInformationStep {...props} />;
      case 1:
        return <IdentificationStep {...props} />;
      case 2:
        return <AddressInformationStep {...props} />;
      case 3:
        return <ContactInformationStep {...props} />;
      default:
        return null;
    }
  };

  const title = (() => {
    if (director?.isVerified || director?.verificationRequested)
      return 'Viewing Director';
    return director ? 'Editing Director' : 'Add Director';
  })();

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box width={'100%'} p={3} height="88vh" overflow="hidden">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box>
            <Typography
              variant="h4"
              gutterBottom={!director || !director?.isVerified}
              color="primary"
              fontWeight="bold"
            >
              {title}
            </Typography>

            {director && (
              <Typography variant="subtitle1" gutterBottom>
                {director?.fullName}
              </Typography>
            )}
          </Box>

          <IconButton onClick={onClose} color="warning">
            <HighlightOffRoundedIcon />
          </IconButton>
        </Box>

        <Divider />

        {director?.isVerified && !director?.verificationRequested && (
          <Box my={1}>
            <Alert color="info">
              You&apos;ll need to contact support to update/edit this director.
            </Alert>
          </Box>
        )}

        {!director?.isVerified && director?.verificationRequested && (
          <Box my={1}>
            <Alert
              severity={director?.verificationRequested ? 'warning' : 'info'}
            >
              You can not edit this director because their profile has been
              submitted for verification.
            </Alert>
          </Box>
        )}

        {!director?.isVerified && !director?.verificationRequested && (
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={4}
          >
            <Box
              maxWidth="800px"
              width="100%"
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step
                    key={label}
                    onClick={() => {
                      if (director) setActiveStep(index);
                    }}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        )}

        <Box height="80%" overflow="auto" pb={20}>
          {!director?.isVerified && !director?.verificationRequested ? (
            <Formik
              enableReinitialize
              onSubmit={handleNext}
              initialValues={initialValues}
              validationSchema={validationSchema[activeStep]}
            >
              {(formikProps) => (
                <Form>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box maxWidth="400px" width="100%">
                      {renderStepContent(activeStep)}
                    </Box>
                  </Box>

                  <Paper
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      py: 2,
                      px: 3,
                      zIndex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {activeStep !== 0 && (
                      <Button
                        onClick={handleBack}
                        variant="contained"
                        sx={{ mr: 2 }}
                      >
                        Back
                      </Button>
                    )}

                    <LoadingButton
                      type="submit"
                      disabled={!formikProps.isValid}
                      color={'primary'}
                      variant="contained"
                      isLoading={isSubmitting}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </LoadingButton>
                  </Paper>
                </Form>
              )}
            </Formik>
          ) : (
            <CompanyDirectorDetailView {...director} />
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default DirectorDrawerForm;
