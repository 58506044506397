import { createBrowserRouter, Navigate } from 'react-router-dom';
import React from 'react';

import Login from '@page/Login';
import Overview from 'pages/Overview';
import UnprotectedRoute from '@component/UnprotectedRoute';
import ProtectedRoute from '@component/ProtectedRoute';
import AuthLayout from '@component/AuthLayout';
import DashboardLayout from '@component/DashboardLayout';
import ViewApps from '@page/ViewApps';
import ManageTeamMembers from '@page/ManageTeamMembers';
import Company from '@page/Company';
import Settings from '@page/Settings';
import ViewApp from 'pages/ViewCreatedApp';
import ErrorBoundary from '@component/ErrorBoundary';
import ViewAppTransactionHistory from '@page/ViewAppTransactionHistory';
import TeamMembers from '@page/TeamMembers';
import AppTransactions from '@page/AppTransactions';
import RoleBasedPermission from '@component/RoleBasedPermission';
import UserRole from '@enum/userRole.enum';

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <UnprotectedRoute>
        <AuthLayout />
      </UnprotectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Login />,
      },
      {
        path: 'login',
        element: <Login />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <ErrorBoundary>
          <DashboardLayout />
        </ErrorBoundary>
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Company />,
        index: true,
      },
      {
        path: 'company',
        element: <Company />,
      },
      {
        path: 'overview',
        element: (
          <RoleBasedPermission
            roles={[
              UserRole.SUPER_ADMIN,
              UserRole.ADMIN,
              UserRole.BUSINESS_OWNER,
              UserRole.BUSINESS_ADMIN,
            ]}
          >
            <Overview />
          </RoleBasedPermission>
        ),
      },
      {
        path: 'apps',
        element: (
          <RoleBasedPermission
            roles={[
              UserRole.SUPER_ADMIN,
              UserRole.ADMIN,
              UserRole.BUSINESS_OWNER,
              UserRole.BUSINESS_ADMIN,
              UserRole.DEVELOPER,
            ]}
          >
            <ViewApps />
          </RoleBasedPermission>
        ),
      },
      {
        path: 'app-transactions',
        element: (
          <RoleBasedPermission
            roles={[
              UserRole.SUPER_ADMIN,
              UserRole.ADMIN,
              UserRole.BUSINESS_OWNER,
              UserRole.BUSINESS_ADMIN,
              UserRole.CUSTOMER_SUPPORT,
              UserRole.FINANCE,
            ]}
          >
            <AppTransactions />
          </RoleBasedPermission>
        ),
      },
      {
        path: 'apps/:id',
        element: (
          <RoleBasedPermission
            roles={[
              UserRole.SUPER_ADMIN,
              UserRole.ADMIN,
              UserRole.BUSINESS_OWNER,
              UserRole.BUSINESS_ADMIN,
              UserRole.DEVELOPER,
            ]}
          >
            <ViewApp />
          </RoleBasedPermission>
        ),
      },
      {
        path: 'apps/:id/history/:currency',
        element: (
          <RoleBasedPermission
            roles={[
              UserRole.SUPER_ADMIN,
              UserRole.ADMIN,
              UserRole.BUSINESS_OWNER,
              UserRole.BUSINESS_ADMIN,
              UserRole.DEVELOPER,
            ]}
          >
            <ViewAppTransactionHistory />
          </RoleBasedPermission>
        ),
      },
      {
        path: 'team',
        element: (
          <RoleBasedPermission
            roles={[
              UserRole.SUPER_ADMIN,
              UserRole.ADMIN,
              UserRole.BUSINESS_OWNER,
              UserRole.BUSINESS_ADMIN,
            ]}
          >
            <TeamMembers />
          </RoleBasedPermission>
        ),
      },
      {
        path: 'team/:id',
        element: (
          <RoleBasedPermission
            roles={[
              UserRole.SUPER_ADMIN,
              UserRole.ADMIN,
              UserRole.BUSINESS_OWNER,
              UserRole.BUSINESS_ADMIN,
            ]}
          >
            <ManageTeamMembers />
          </RoleBasedPermission>
        ),
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: '*',
        element: <Company />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/login" />,
  },
]);

export default router;
