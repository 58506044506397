import { Field, Form, useFormikContext } from 'formik';
import {
  Box,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import GroupRemoveRoundedIcon from '@mui/icons-material/GroupRemoveRounded';
import isEqual from 'lodash.isequal';
import React from 'react';

import StatusDot from '@component/StatusDot';
import { USER_ROLES } from '@variable';
import LoadingButton from '@component/LoadingButton';
import User from '@interface/user.interface';

const GRID_PROPS = { item: true, xs: 12, md: 6 };

const TeamMemberDetailForm = ({
  isEditing,
  isTerminated,
  initialValues,
  onSetRehireDialogOpen,
  isRehiringTeamMember,
  lastLoggedInAt,
}: any) => {
  const { errors, touched, isValid, isSubmitting, values } =
    useFormikContext<User>();

  return (
    <Form>
      <Typography variant="h6" gutterBottom={!isEditing}>
        {isEditing ? 'Edit Team Member' : 'Add Team Member'}
      </Typography>

      {isEditing && (
        <Stack
          mb={4}
          direction="row"
          gap={2}
          justifyContent="center"
          alignItems="center"
          width="-webkit-fit-content"
        >
          {!isTerminated ? (
            <>
              <Typography color="text.secondary" variant="subtitle2">
                {lastLoggedInAt
                  ? 'User has logged in'
                  : 'User has not logged in'}
              </Typography>
              <StatusDot
                isVerified={Boolean(lastLoggedInAt)}
                text={
                  lastLoggedInAt
                    ? 'User has logged in'
                    : 'User has never logged in'
                }
              />
            </>
          ) : (
            <Box alignItems="center" flexDirection="row" display="flex" gap={1}>
              <Typography color="error">User Terminated</Typography>
              <GroupRemoveRoundedIcon color="error" />
            </Box>
          )}
        </Stack>
      )}

      <Grid container spacing={3}>
        <Grid {...GRID_PROPS}>
          <Box mb={2}>
            <Field
              disabled={isTerminated}
              as={TextField}
              name="firstName"
              label="First Name"
              fullWidth
              variant="outlined"
              error={touched.firstName && !!errors.firstName}
              helperText={touched.firstName && errors.firstName}
            />
          </Box>
        </Grid>
        <Grid {...GRID_PROPS}>
          <Box mb={2}>
            <Field
              disabled={isTerminated}
              as={TextField}
              name="lastName"
              label="Last Name"
              fullWidth
              variant="outlined"
              error={touched.lastName && !!errors.lastName}
              helperText={touched.lastName && errors.lastName}
            />
          </Box>
        </Grid>
        <Grid {...GRID_PROPS}>
          <Box mb={2}>
            <Field
              disabled={isTerminated || isEditing}
              as={TextField}
              name="email"
              label="Email"
              fullWidth
              variant="outlined"
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
          </Box>
        </Grid>
        <Grid {...GRID_PROPS}>
          <Box mb={2}>
            <Field
              disabled={isTerminated}
              as={TextField}
              name="role"
              label="Role"
              select
              fullWidth
              variant="outlined"
              error={touched.role && !!errors.role}
              helperText={touched.role && errors.role}
            >
              {USER_ROLES.map(({ value, title }) => (
                <MenuItem key={value} value={value}>
                  {title}
                </MenuItem>
              ))}
            </Field>
          </Box>
        </Grid>
      </Grid>

      {!isTerminated && (
        <Box mt={3} display="flex" justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid || isEqual(values, initialValues)}
            isLoading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Box>
      )}

      {isTerminated && (
        <Box mt={3} display="flex" justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => {
              onSetRehireDialogOpen(true);
            }}
            isLoading={isRehiringTeamMember}
          >
            Rehire
          </LoadingButton>
        </Box>
      )}
    </Form>
  );
};

export default TeamMemberDetailForm;
