import React, { FC } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface TaskItemProps {
  label: string;
  link: string;
  isComplete: boolean;
}

const TaskItem: FC<TaskItemProps> = ({ label, isComplete, link }) => {
  const navigate = useNavigate();
  const theme: any = useTheme();

  return (
    <Box mb={2}>
      <FormControlLabel
        control={<Checkbox checked={isComplete} disabled />}
        label={
          <Typography
            variant="body1"
            sx={{
              textDecoration: isComplete ? 'line-through' : 'none',
              ':hover': {
                cursor: !isComplete ? 'pointer' : 'initial',
                textDecoration: !isComplete ? 'underline' : 'initial',
                color: !isComplete ? theme.palette.primary.main : 'initial',
              },
            }}
            onClick={() => (isComplete ? undefined : navigate(link))}
          >
            {label}
          </Typography>
        }
      />
    </Box>
  );
};

export default TaskItem;
