import React from 'react';
import { Typography } from '@mui/material';

import TabPanel from '@component/AppDetail/TabPanel';
import AppWalletInterface from '@interface/appWallet.interface';
import WalletBalance from '@component/WalletBalance';

const AppWallet = ({
  appId,
  value,
  wallets,
  index,
}: {
  appId: string;
  value: number;
  wallets: AppWalletInterface[];
  index: number;
}) => {
  return (
    <TabPanel value={value} index={index}>
      <Typography variant="h6" fontWeight="bold" color="primary">
        Available Balances
      </Typography>
      <Typography mb={2} variant="subtitle1">
        This name will be displayed to your customers at payment time
      </Typography>

      <WalletBalance wallets={wallets} appId={appId} />
    </TabPanel>
  );
};

export default AppWallet;
