import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CRYPTOCURRENCY_ICONS, WITHDRAWAL_FEES } from '@variable';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectCanWithdrawFromWallet } from '@selector/permission.selector';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import WithdrawalDialog from '@component/WithdrawalDialog';
import ConfirmationDialog from '@component/ConfirmationDialog';
import { withdrawCrypto } from '@action/app.action';

interface AppWalletCardProps {
  currency: CryptoCurrency;
  balance: number;
  appId: string;
  id: string;
}

const ConfirmationField = ({ title, value }: any) => {
  return (
    <Box sx={{ minWidth: 450 }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        fontSize={16}
      >
        <b>{title}:</b> <span>{value}</span>
      </Box>
    </Box>
  );
};

const AppWalletCard: FC<AppWalletCardProps> = ({
  id,
  currency,
  balance,
  appId,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const canWithdrawFromWallet = useAppSelector(selectCanWithdrawFromWallet);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState<number>(0);
  const [enteredAddress, setEnteredAddress] = useState<string>('');

  const handleOpenDialog = () => {
    if (balance > 0) setDialogOpen(true);
  };

  const handleCloseDialog = () => setDialogOpen(false);

  const handleConfirmWithdrawal = useCallback(
    async (amount: number, address: string) => {
      setEnteredAmount(amount);
      setEnteredAddress(address);
      setOpenConfirmationDialog(true);
      setDialogOpen(false);
    },
    [],
  );

  const fields = [
    {
      title: 'Address',
      value: enteredAddress,
    },
    {
      title: 'Amount',
      value: `${enteredAmount} ${currency}`,
    },
    {
      title: 'Fee',
      value: `${WITHDRAWAL_FEES[currency]} ${currency}`,
    },
  ];

  const confirmationDialogProps = {
    title: 'Withdraw',
    open: openConfirmationDialog,
    confirmButtonText: 'Withdraw',
    subtitle: (
      <>
        <img
          src={CRYPTOCURRENCY_ICONS[currency]}
          alt={`${currency} icon`}
          height={35}
          width={35}
          style={{ marginBottom: 10 }}
        />
        <Box
          sx={{}}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          {fields.map((field) => (
            <ConfirmationField key={field.title} {...field} />
          ))}
          <Divider variant="fullWidth" sx={{ width: '100%' }} />
          <ConfirmationField
            {...{
              title: 'You receive',
              value: `${enteredAmount - WITHDRAWAL_FEES[currency]} ${currency}`,
            }}
          />
        </Box>
      </>
    ),
    onClose: () => {
      setOpenConfirmationDialog(false);
    },
    onConfirm: () => {
      dispatch(
        withdrawCrypto({
          id: appId,
          walletId: id,
          amount: enteredAmount,
          address: enteredAddress,
        }),
      );
    },
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent sx={{ position: 'relative' }}>
            <img
              src={CRYPTOCURRENCY_ICONS[currency]}
              alt={`${currency} icon`}
              height={35}
              width={35}
              style={{ position: 'absolute', top: 16, right: 16 }}
            />

            <Stack>
              <Typography variant="subtitle2">Balance</Typography>
              <Typography variant="h6">
                {balance} {currency}
              </Typography>
            </Stack>

            <CardActions sx={{ p: 0, justifyContent: 'flex-end' }}>
              {canWithdrawFromWallet && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenDialog}
                  sx={{ mt: 2, flexGrow: 1 }}
                  size={'small'}
                  disabled={balance === 0}
                >
                  Withdraw
                </Button>
              )}
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  mt: 2,
                  flexGrow: canWithdrawFromWallet ? 1 : undefined,
                }}
                onClick={() => navigate(`history/${currency}`)}
                size={'small'}
              >
                History
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
      {dialogOpen && (
        <WithdrawalDialog
          open={dialogOpen}
          balance={balance}
          onClose={handleCloseDialog}
          cryptocurrency={currency}
          onConfirm={handleConfirmWithdrawal}
          previousAddresses={[]}
        />
      )}

      {openConfirmationDialog && (
        <ConfirmationDialog {...confirmationDialogProps} />
      )}
    </>
  );
};

export default AppWalletCard;
