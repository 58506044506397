import { createSelector } from '@reduxjs/toolkit';

import { SLICE_NAME } from '@variable';
import { RootState } from '@store';
import Company from '@interface/company.interface';

export const selectCompany = (state: RootState) => state[SLICE_NAME].company;

export const selectCompanyInfo = createSelector(
  selectCompany,
  (company?: Company) => {
    return {
      name: company?.name,
      type: company?.type,
      taxRate: company?.taxRate,
      feeRate: company?.feeRate,
      isVerified: company?.isVerified,
      isRegistered: company?.isRegistered,
      supportEmail: company?.supportEmail,
      cacDocUpload: company?.cacDocUpload,
      supportPhoneNumber: company?.supportPhoneNumber,
      verificationRequested: company?.verificationRequested,
      countryOfIncorporation: company?.countryOfIncorporation,
    } as Company;
  },
);

export const selectCompanyDirectors = createSelector(
  selectCompany,
  (company?: Company) => company?.directors,
);

export const selectVerifiedCompanyDirectors = createSelector(
  selectCompanyDirectors,
  (directors) => directors?.filter((director) => director.isVerified),
);

export const selectUnverifiedCompanyDirectors = createSelector(
  selectCompanyDirectors,
  (directors) =>
    directors?.filter(
      (director) => !director.isVerified && !director.verificationRequested,
    ),
);

export const selectPendingVerificationCompanyDirectors = createSelector(
  selectCompanyDirectors,
  (directors) =>
    directors?.filter(
      (director) => director.verificationRequested && !director.isVerified,
    ),
);

export const selectTeamMembers = (state: RootState) =>
  state[SLICE_NAME].teamMembers;

export const selectIsLoadingCompany = (state: RootState) =>
  state[SLICE_NAME].isLoadingCompany;

export const selectTeamMemberById = createSelector(
  [selectTeamMembers, (_, props) => props],
  (teamMembers, { id }) => {
    return teamMembers.find((user) => user.id === id);
  },
);

export const selectIsLoadingCompanyTeamMembers = (state: RootState) =>
  state[SLICE_NAME].isLoadingCompanyTeamMembers;

export const selectIsUpdatingCompany = (state: RootState) =>
  state[SLICE_NAME].isUpdatingCompany;
