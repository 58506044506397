import { Navigate, useLocation } from 'react-router-dom';
import React, { FC, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@mui/material';

import { useAuth } from '@hook/useAuth.hook';
import { useAppSelector } from '@hook/hooks.hook';
import { selectUser } from '@selector/auth.selector';

import AppLoader from '../AppLoader';

const NotVerified = () => (
  <Stack>
    <p>Your email is not verified</p>
    <p>
      If you are seeing this screen, it is because you did not click the link
      that was sent to your email after you signed up.
    </p>
    <p>Please check your email for the verification link.</p>
  </Stack>
);

const ProtectedRoute: FC<{ children: any }> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const user = useAppSelector(selectUser);
  const { setRedirectPath } = useAuth();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!isAuthenticated) setRedirectPath(pathname + search || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (!isAuthenticated) return <Navigate to="/login" />;
  if (isLoading || !user) return <AppLoader />;
  return !user?.isVerified ? <NotVerified /> : children;
};

export default ProtectedRoute;
