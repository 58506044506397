import { Box, Toolbar } from '@mui/material';
import { Outlet as RouterOutlet } from 'react-router-dom';
import React from 'react';

const MainContent = () => (
  <Box
    component="main"
    sx={{
      p: 3,
      flexGrow: 1,
    }}
  >
    <Toolbar />
    <RouterOutlet />
  </Box>
);

export default MainContent;
