import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import isEqual from 'lodash.isequal';
import React, { useCallback, useState } from 'react';

import FileUploadField from '@component/DirectorDrawerForm/FileUploadField';
import LoadingButton from '@component/LoadingButton';
import BusinessOwnerKyc from '@interface/businessOwnerKyc.interface';
import { STATES_MAP } from '@variable';
import ConfirmationDialog from '@component/ConfirmationDialog';
import { useAppDispatch } from '@hook/hooks.hook';
import { requestBusinessOwnerVerification } from '@action/company.action';
import PhoneNumberField from '@component/PhoneNumberField';
import CountrySelectField from '@component/CountrySelectField';

const BusinessForm = ({
  isVerified,
  initialValues,
}: {
  isVerified: boolean;
  initialValues: BusinessOwnerKyc;
}) => {
  const { touched, setFieldValue, isValid, errors, isSubmitting, values } =
    useFormikContext<BusinessOwnerKyc>();

  const [openConfirmRequestVerification, setOpenConfirmRequestVerification] =
    useState(false);

  const hasEqualValues = isEqual(initialValues, {
    firstName: values.firstName,
    lastName: values.lastName,
    dateOfBirth: values.dateOfBirth,
    nationality: values.nationality,
    idUpload: values.idUpload,
    email: values.email,
    phoneNumber: values.phoneNumber,
    countryOfResidence: values.countryOfResidence,
    street: values.street,
    city: values.city,
    state: values.state,
    utilityBillUpload: values.utilityBillUpload,
  });

  const disableSubmitButton = !isValid || hasEqualValues;

  const showRequestVerificationButton =
    hasEqualValues &&
    initialValues.firstName &&
    initialValues.lastName &&
    initialValues.dateOfBirth &&
    initialValues.nationality &&
    initialValues.idUpload &&
    initialValues.email &&
    initialValues.phoneNumber &&
    initialValues.countryOfResidence &&
    initialValues.street &&
    initialValues.city &&
    initialValues.state &&
    initialValues.utilityBillUpload;

  const handleChangeCountryOfResidence = useCallback(
    (e: any) => {
      setFieldValue('state', '');
      setFieldValue('countryOfResidence', e.target.value);
    },
    [setFieldValue],
  );

  const dispatch = useAppDispatch();

  const handleConfirmRequestVerification = () => {
    dispatch(requestBusinessOwnerVerification());
  };

  return (
    <Paper elevation={3} sx={{ p: 4 }}>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="h6" fontWeight="bold">
          Business Owner KYC
        </Typography>
        <Chip
          size="small"
          color={isVerified ? 'primary' : 'error'}
          label={isVerified ? 'Verified' : 'Not Verified'}
        />
      </Stack>

      <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />

      {showRequestVerificationButton && (
        <Alert severity="info" sx={{ mb: 4 }}>
          <AlertTitle sx={{ fontWeight: 'bold' }}>
            Request Verification
          </AlertTitle>
          Please review your details and click the &quot;Request
          Verification&quot; button to request verification
        </Alert>
      )}

      <Grid container spacing={6}>
        <Grid container xs={12} spacing={2} item>
          <Grid item xs={12} sm={6}>
            <Field
              as={TextField}
              name="firstName"
              label="First Name"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              as={TextField}
              name="lastName"
              label="Last Name"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              as={TextField}
              name="email"
              label="Email"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              component={({ field, form }: any) => (
                <DatePicker
                  label="Date of Birth"
                  value={field.value ? dayjs(field.value) : undefined}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                  sx={{ width: '100%' }}
                />
              )}
              name="dateOfBirth"
              label="Date of Birth"
              type="date"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              helperText={touched.dateOfBirth && errors.dateOfBirth}
            />
            {touched.dateOfBirth && Boolean(errors.dateOfBirth) && (
              <Typography variant="caption" color="error">
                Must be at least 18 years old
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <CountrySelectField
              name="nationality"
              label="Nationality"
              error={touched.nationality && !!errors.nationality}
              helperText={touched.nationality && errors.nationality}
            />
          </Grid>
        </Grid>

        <Grid container xs={12} spacing={2} item>
          <Grid item xs={12} sm={6}>
            <CountrySelectField
              name="countryOfResidence"
              label="Country of Residence"
              error={touched.countryOfResidence && !!errors.countryOfResidence}
              helperText={
                touched.countryOfResidence && errors.countryOfResidence
              }
              onChange={handleChangeCountryOfResidence}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              as={TextField}
              name="state"
              label="Region"
              select
              fullWidth
              variant="outlined"
              error={touched.state && !!errors.state}
              helperText={touched.state && errors.state}
            >
              {(values.countryOfResidence
                ? STATES_MAP[values.countryOfResidence]
                : []
              ).map(({ value, title }: any) => (
                <MenuItem key={value} value={value}>
                  {title}
                </MenuItem>
              ))}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              as={TextField}
              name="city"
              label="City"
              fullWidth
              variant="outlined"
              error={touched.city && !!errors.city}
              helperText={touched.city && errors.city}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              as={TextField}
              name="street"
              label="Street"
              fullWidth
              variant="outlined"
              error={touched.street && !!errors.street}
              helperText={touched.street && errors.street}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {values.countryOfResidence && (
              <PhoneNumberField
                as={TextField}
                name="phoneNumber"
                country={values.countryOfResidence}
                label="Phone Number"
                fullWidth
                variant="outlined"
              />
            )}
          </Grid>
        </Grid>

        <Grid container xs={12} spacing={2} item>
          <Grid item xs={12} sm={6}>
            <FileUploadField
              name="idUpload"
              uwConfig={{
                folder: 'identification',
                tags: ['apps', 'identification', 'verification'],
              }}
              label="Upload Identification (Only government issued IDs)"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FileUploadField
              uwConfig={{
                folder: 'utility_bills',
                tags: ['apps', 'utility_bills', 'verification'],
              }}
              name="utilityBillUpload"
              label="Upload Proof of address (Utility bill)"
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider variant="fullWidth" sx={{ mt: 4, mb: 3 }} />

      <Box mt={2}>
        {showRequestVerificationButton ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setOpenConfirmRequestVerification(true);
            }}
          >
            Request Verification
          </Button>
        ) : (
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            isLoading={isSubmitting}
            disabled={disableSubmitButton}
            size="large"
          >
            Save
          </LoadingButton>
        )}
      </Box>

      {openConfirmRequestVerification && (
        <ConfirmationDialog
          title="Request Verification"
          subtitle={
            'Ensure you have checked all the details before you submit this form'
          }
          open={openConfirmRequestVerification}
          onClose={() => {
            setOpenConfirmRequestVerification(false);
          }}
          onConfirm={handleConfirmRequestVerification}
          confirmButtonText="Submit"
          cancelButtonText="Cancel"
        />
      )}
    </Paper>
  );
};

export default BusinessForm;
