import React from 'react';
import { Button, ButtonProps } from '@mui/material';

import Spinner from '@component/Spinner';

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  children,
  isLoading,
  ...props
}) => {
  return (
    <Button {...props} disabled={isLoading || props.disabled}>
      {children}
      {isLoading && (
        <span style={{ marginLeft: 10 }}>
          <Spinner size={15} color="inherit" />
        </span>
      )}
    </Button>
  );
};

export default LoadingButton;
