import React, { createContext, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { UW_CONFIG } from '@variable';
import LoadingButton from '@component/LoadingButton';

const CloudinaryScriptContext = createContext({});

function CloudinaryUploadWidget({ children, setPublicId, uwConfig = {} }: any) {
  const [isInitializing, setIsInitializing] = useState(false);

  const initializeCloudinaryWidget = useCallback(() => {
    if (isInitializing) return;
    setIsInitializing(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const myWidget = window.cloudinary.createUploadWidget(
      { ...UW_CONFIG, ...uwConfig },
      (error: any, result: any) => {
        if (!error && result && result.event === 'success') {
          setPublicId(result.info.public_id);
        }
      },
    );

    myWidget.open();

    setTimeout(() => {
      setIsInitializing(false);
    }, 250);
  }, [isInitializing, setPublicId, uwConfig]);

  return (
    <CloudinaryScriptContext.Provider value={{ loaded: isInitializing }}>
      <LoadingButton
        variant="contained"
        component="label"
        sx={{ p: 0 }}
        isLoading={isInitializing}
      >
        <Box
          id="upload_widget"
          sx={{
            display: 'flex',
            minWidth: '150px',
            minHeight: '150px',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={initializeCloudinaryWidget || undefined}
        >
          <Box
            sx={{
              top: 0,
              left: 0,
              zIndex: 1,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              flexDirection: 'column',
              justifyContent: 'center',
              background: 'rgba(0,0,0,0.7)',
              opacity: !children ? 1 : 0,
              '&:hover': {
                opacity: 1,
                cursor: 'pointer',
              },
            }}
          >
            <CloudUploadIcon color="secondary" fontSize="large" />
            <Typography color="secondary">Upload item</Typography>
          </Box>
          {children}
        </Box>
      </LoadingButton>
    </CloudinaryScriptContext.Provider>
  );
}

export default CloudinaryUploadWidget;
export { CloudinaryScriptContext };
