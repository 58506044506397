import React from 'react';
import { useField } from 'formik';
import { Box, Typography } from '@mui/material';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';

import cld from '@localCloudinary';
import CloudinaryUploadWidget from '@component/CloudinaryUploadWidgets';

const FileUploadField = ({
  label,
  height,
  width,
  readOnly,
  uwConfig = {},
  name,
}: any) => {
  const [inputProps, meta, helpers] = useField(name);

  const handleFileUpload = async (fileUrl: string) => {
    await helpers.setValue(fileUrl);
  };

  const img = cld
    .image(inputProps.value)
    .format('auto')
    .quality('auto')
    .resize(auto().gravity(autoGravity()).width(400).height(400));

  return (
    <Box height={height} width={width}>
      {label && (
        <Typography gutterBottom variant="subtitle1" color="text.secondary">
          {label}:
        </Typography>
      )}
      {readOnly ? (
        <AdvancedImage
          cldImg={img}
          style={{
            maxWidth: 400,
            height: 'auto',
            width: '100%',
          }}
        />
      ) : (
        <CloudinaryUploadWidget
          setPublicId={handleFileUpload}
          label={label}
          uwConfig={{
            croppingAspectRatio: undefined,
            maxImageWidth: undefined,
            maxImageHeight: undefined,
            maxImageFileSize: 3000000,
            clientAllowedFormats: ['jpeg', 'png', 'webp', 'jpg', 'pdf'],
            ...uwConfig,
          }}
        >
          <AdvancedImage
            cldImg={img}
            style={{
              maxWidth: 400,
              height: 'auto',
              width: '100%',
            }}
          />
        </CloudinaryUploadWidget>
      )}

      {meta.touched && meta.error && (
        <Box>
          <Typography variant="caption" color="error">
            {meta.error}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FileUploadField;
