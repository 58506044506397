import React from 'react';
import { Box, Tooltip, useTheme } from '@mui/material';

interface StatusDotProps {
  isVerified: boolean;
  text: string;
}

const StatusDot: React.FC<StatusDotProps> = ({ isVerified, text }) => {
  const theme: any = useTheme();

  return (
    <Tooltip title={text}>
      <Box
        component="span"
        sx={{
          display: 'inline-block',
          width: 10,
          height: 10,
          borderRadius: '50%',
          bgcolor: theme.palette[isVerified ? 'success' : 'error'].main,
          mr: 1,
        }}
      />
    </Tooltip>
  );
};

export default StatusDot;
