import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';

import { createApp } from '@action/app.action';
import { useAppDispatch } from '@hook/hooks.hook';
import LoadingButton from '@component/LoadingButton';
import { FIAT_CURRENCIES } from '@variable';

interface CreateAppDialogProps {
  open: boolean;
  onClose: () => void;
}

const CreateAppDialog: React.FC<CreateAppDialogProps> = ({ open, onClose }) => {
  const [appName, setAppName] = useState('');
  const [isCreatingApp, setIsCreatingApp] = useState(false);
  const [fiatCurrency, setFiatCurrency] = useState('');
  const dispatch = useAppDispatch();
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppName(event.target.value);
  };

  const handleSubmit = async () => {
    if (!appName || !fiatCurrency) return;

    setIsCreatingApp(true);
    const res = (await dispatch(
      createApp({ name: appName, fiatCurrency }),
    )) as any;

    if (!res.error) {
      setAppName('');
      setFiatCurrency('');
      onClose();
    }

    setIsCreatingApp(false);
  };

  const handleCancel = () => {
    setAppName(''); // Clear the input on cancel
    onClose();
  };

  const handleFiatCurrencyChange = (e: any) => {
    setFiatCurrency(e.target.value);
  };

  const disableSubmitButton = !appName || !fiatCurrency;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>New App</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Please enter the name of the new app you want to create.
        </DialogContentText>
        <Stack gap={2}>
          <TextField
            margin="dense"
            label="App Name"
            type="text"
            fullWidth
            value={appName}
            onChange={handleNameChange}
          />

          <TextField
            select
            value={fiatCurrency}
            onChange={handleFiatCurrencyChange}
            label="Select app currency"
            helperText="You can never change this currency"
          >
            {FIAT_CURRENCIES.map(({ code: value, name: title }, index) => (
              <MenuItem key={value + index} value={value}>
                {title}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2, justifyContent: 'space-between' }}>
        <Button onClick={handleCancel} variant="outlined" color="error">
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="outlined"
          onClick={handleSubmit}
          isLoading={isCreatingApp}
          disabled={disableSubmitButton}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAppDialog;
