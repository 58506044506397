import React from 'react';
import { Grid } from '@mui/material';

import AppWalletInterface from '@interface/appWallet.interface';
import AppWalletCard from '@component/WalletBalance/AppWalletCard';

interface WalletBalanceProps {
  wallets: AppWalletInterface[];
  appId: string;
}

const WalletBalance: React.FC<WalletBalanceProps> = ({ wallets, appId }) => {
  return (
    <Grid container spacing={2}>
      {wallets.map(({ currency, balance, id }) => (
        <AppWalletCard
          key={id}
          currency={currency}
          balance={balance}
          id={id}
          appId={appId}
        />
      ))}
    </Grid>
  );
};

export default WalletBalance;
