import React, { FC, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import {
  selectCompanyDirectors,
  selectCompanyInfo,
} from '@selector/company.selector';
import { selectBusinessOwnerKyc } from '@selector/auth.selector';
import { getCompanyDetails } from '@action/company.action';

import ProgressIndicator from './ProgressIndicator';
import Checklist from './Checklist';
import SuccessState from './SuccessState';
import SupportSection from './SupportSection';

const OverviewPage: FC = () => {
  const company = useAppSelector(selectCompanyInfo);
  const businessOwnerKyc = useAppSelector(selectBusinessOwnerKyc);
  const dispatch = useAppDispatch();

  const companyDirectors = useAppSelector(selectCompanyDirectors);
  const companyDirectorsVerificationDone = Boolean(
    companyDirectors?.length &&
      companyDirectors.some(
        (director) => director.verificationRequested || director.isVerified,
      ),
  );
  const businessOwnerIsVerified = Boolean(
    businessOwnerKyc?.isVerified || businessOwnerKyc?.verificationRequested,
  );
  const companyVerificationDone = Boolean(
    company.verificationRequested || company.isVerified,
  );

  const isChecklistComplete =
    (!company.isRegistered ||
      (companyDirectorsVerificationDone && company.isVerified)) &&
    businessOwnerKyc?.isVerified;
  useEffect(() => {
    dispatch(getCompanyDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      sx={{
        width: '100%',
        maxWidth: 'lg',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        // height: '100%',
      }}
    >
      <Box p={4} mx="auto">
        <Typography variant="h3" gutterBottom>
          Overview
        </Typography>

        {!isChecklistComplete ? (
          <>
            <ProgressIndicator
              companyVerificationDone={companyVerificationDone}
              companyDirectorsVerificationDone={
                companyDirectorsVerificationDone
              }
              businessOwnerIsVerified={businessOwnerIsVerified}
              isRegisteredCompany={Boolean(company?.isRegistered)}
            />
            <Checklist
              isRegisteredCompany={Boolean(company?.isRegistered)}
              companyVerificationDone={companyVerificationDone}
              companyDirectorsVerificationDone={
                companyDirectorsVerificationDone
              }
              businessOwnerIsVerified={businessOwnerIsVerified}
            />
          </>
        ) : (
          <SuccessState />
        )}
        <SupportSection />
      </Box>
    </Paper>
  );
};

export default OverviewPage;
