import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SuccessState: FC = () => {
  const navigate = useNavigate();

  const goToAppsScreen = () => {
    navigate('/dashboard/apps');
  };

  return (
    <Box textAlign="center" py={4}>
      <Typography variant="h5" gutterBottom>
        Congratulations!
      </Typography>
      <Typography variant="body1" mb={4}>
        You have completed all the necessary steps. Your apps are now ready to
        move to production.
      </Typography>
      <Button variant="contained" color="primary" onClick={goToAppsScreen}>
        Start Integrating Your App
      </Button>
    </Box>
  );
};

export default SuccessState;
