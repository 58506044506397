import React, { FC, MouseEvent, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AddCircleOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { red } from '@mui/material/colors';

import StatusDot from '@component/StatusDot';
import User from '@interface/user.interface';
import AppLoader from '@component/AppLoader';
import { ROLE_MAP } from '@variable';
import UserStatus from '@enum/userStatus.enum';

interface TeamMemberTableProps {
  teamMembers: User[];
  isLoadingCompanyTeamMembers: boolean;
}

const TeamMemberTable: FC<TeamMemberTableProps> = ({
  teamMembers,
  isLoadingCompanyTeamMembers,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMember, setSelectedMember] = useState<null | User>(null);

  const handleMenuClick = (
    event: MouseEvent<HTMLButtonElement>,
    member: User,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedMember(member);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMember(null);
  };

  const handleEdit = useCallback(() => {
    if (selectedMember) {
      navigate(selectedMember.id as string);
      handleMenuClose();
    }
  }, [navigate, selectedMember]);
  const [showTerminated, setShowTerminated] = useState(false);
  const filteredTeamMembers = showTerminated
    ? teamMembers
    : teamMembers.filter(
        (teamMember) => !(teamMember.status === UserStatus.TERMINATED),
      );

  return (
    <>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h4">
          Team Members ({filteredTeamMembers.length})
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {/*  add checkbox */}

          <FormControlLabel
            control={<Checkbox checked={showTerminated} />}
            label="Show terminated"
            onClick={() => {
              setShowTerminated((value) => !value);
            }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate('new');
            }}
            startIcon={<AddCircleOutline />}
          >
            Add Team Member
          </Button>
        </Box>
      </Toolbar>

      <Paper elevation={3} sx={{ p: 4 }}>
        {filteredTeamMembers?.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTeamMembers.map((member) => {
                  const isTerminated = member.status === UserStatus.TERMINATED;

                  return (
                    <TableRow
                      key={member.email}
                      sx={{
                        backgroundColor: isTerminated ? red['100'] : 'initial',
                      }}
                    >
                      <TableCell>{member.firstName}</TableCell>
                      <TableCell>{member.lastName}</TableCell>
                      <TableCell>{member.email}</TableCell>
                      <TableCell>
                        {member.phoneNumber ? '0' + member.phoneNumber : 'N/A'}
                      </TableCell>
                      <TableCell>{ROLE_MAP[member.role]}</TableCell>
                      <TableCell>
                        <StatusDot
                          isVerified={Boolean(member.lastLoggedInAt)}
                          text={
                            member.lastLoggedInAt
                              ? 'User has logged in'
                              : 'User has never logged in'
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(event) => handleMenuClick(event, member)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={handleEdit}>Edit</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {isLoadingCompanyTeamMembers && (
              <Box my={4}>
                <AppLoader size={8} />
              </Box>
            )}
          </TableContainer>
        ) : (
          <Typography variant="h6">No team members</Typography>
        )}
      </Paper>
    </>
  );
};

export default TeamMemberTable;
