import React, { FC } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

const ProgressIndicator: FC<{
  companyVerificationDone: boolean;
  companyDirectorsVerificationDone: boolean;
  businessOwnerIsVerified: boolean;
  isRegisteredCompany: boolean;
}> = ({
  companyVerificationDone,
  companyDirectorsVerificationDone,
  businessOwnerIsVerified,
  isRegisteredCompany,
}) => {
  const parameters = [businessOwnerIsVerified];

  if (isRegisteredCompany) {
    parameters.push(companyVerificationDone);
    parameters.push(companyDirectorsVerificationDone);
  }

  const progress = parameters.reduce((acc, curr) => {
    return acc + (curr ? 100 / parameters.length : 0);
  }, 0);

  return (
    <Box mb={4}>
      <Typography variant="body1" gutterBottom>
        Progress
      </Typography>
      <LinearProgress variant="determinate" value={progress} />
      <Typography variant="body2" color="textSecondary">
        {Math.round(progress)}% Complete
      </Typography>
    </Box>
  );
};

export default ProgressIndicator;
