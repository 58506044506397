import React, { useState } from 'react';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

import { AppTransactionAttributes } from '@interface/appTransaction.interface';
import { TransactionType } from '@enum/transactionType.enum';
import AppLoader from '@component/AppLoader';
import TransactionStatus from '@component/TransactionStatus';

interface TransactionsTableProps {
  hasMore: boolean;
  appTransactionLoading: boolean;
  loadMoreTransactions: () => void;
  transactions: AppTransactionAttributes[];
}

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  hasMore,
  transactions = [],
  loadMoreTransactions,
  appTransactionLoading,
}) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof AppTransactionAttributes>('createdAt');

  const handleRequestSort = (property: keyof AppTransactionAttributes) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedTransactions = transactions.slice().sort((a, b) => {
    if (orderBy === 'cryptocurrency') {
      return order === 'asc'
        ? a.cryptocurrency.localeCompare(b.cryptocurrency)
        : b.cryptocurrency.localeCompare(a.cryptocurrency);
    }
    const aValue = a[orderBy] || 0;
    const bValue = b[orderBy] || 0;
    return order === 'asc'
      ? aValue < bValue
        ? -1
        : 1
      : aValue > bValue
      ? -1
      : 1;
  });

  const openBlockchainTransactionDetails = (
    blockchainTransactionId?: string,
  ) => {
    if (!blockchainTransactionId) return;
    window.open(
      'https://tronscan.org/#/transaction/' + blockchainTransactionId,
      '_blank',
    );
  };

  return sortedTransactions.length ? (
    <Paper>
      <>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ padding: 2 }}
        >
          Past Transactions
        </Typography>

        <TableContainer>
          <InfiniteScroll
            hasMore={hasMore}
            next={loadMoreTransactions}
            loader={<CircularProgress />}
            dataLength={transactions.length}
            scrollableTarget="scrollableDiv"
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection={orderBy === 'type' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'type'}
                      direction={orderBy === 'type' ? order : 'asc'}
                      onClick={() => handleRequestSort('type')}
                    >
                      Type
                    </TableSortLabel>
                  </TableCell>

                  <TableCell
                    sortDirection={orderBy === 'createdAt' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'createdAt'}
                      direction={orderBy === 'createdAt' ? order : 'asc'}
                      onClick={() => handleRequestSort('createdAt')}
                    >
                      Created At
                    </TableSortLabel>
                  </TableCell>

                  <TableCell
                    sortDirection={
                      orderBy === 'cryptocurrencyAmount' ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === 'cryptocurrencyAmount'}
                      direction={
                        orderBy === 'cryptocurrencyAmount' ? order : 'asc'
                      }
                      onClick={() => handleRequestSort('cryptocurrencyAmount')}
                    >
                      Cryptocurrency Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={
                      orderBy === 'blockchainTransactionId' ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === 'blockchainTransactionId'}
                      direction={
                        orderBy === 'blockchainTransactionId' ? order : 'asc'
                      }
                      onClick={() => handleRequestSort('fiatAmount')}
                    >
                      Blockchain #
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedTransactions.map((transaction, index) => (
                  <TableRow
                    key={index}
                    onClick={() =>
                      openBlockchainTransactionDetails(
                        transaction.blockchainTransactionId,
                      )
                    }
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      <Tooltip title={transaction.type} arrow placement="right">
                        {transaction.type === TransactionType.INCOMING ? (
                          <ArrowDownward color="success" />
                        ) : (
                          <ArrowUpward color="warning" />
                        )}
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {dayjs(transaction.createdAt).format(
                        'MMMM D, YYYY hh:mm A',
                      )}
                    </TableCell>
                    <TableCell>
                      {transaction.cryptocurrencyAmount}{' '}
                      {transaction.cryptocurrency}
                    </TableCell>
                    <TableCell>
                      {transaction.blockchainTransactionId
                        ? '#' + transaction.blockchainTransactionId
                        : ''}
                    </TableCell>
                    <TableCell>
                      <TransactionStatus status={transaction.status} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {appTransactionLoading && (
              <Box my={4}>
                <AppLoader size={8} />
              </Box>
            )}
          </InfiniteScroll>
        </TableContainer>
      </>
    </Paper>
  ) : (
    <Box mt={1}>
      <Typography variant="h6">No past transactions</Typography>
    </Box>
  );
};

export default TransactionsTable;
