import { Navigate } from 'react-router-dom';
import React, { FC } from 'react';

import UserRole from '@enum/userRole.enum';
import { useAppSelector } from '@hook/hooks.hook';
import { selectUserRole } from '@selector/permission.selector';

const RoleBasedPermission: FC<{ children: any; roles: UserRole[] }> = ({
  children,
  roles,
}) => {
  const userRole = useAppSelector(selectUserRole);

  return roles.includes(userRole as UserRole) ? (
    children
  ) : (
    <Navigate to="/dashboard/company" />
  );
};

export default RoleBasedPermission;
