import React, { FC } from 'react';
import { Box, Link, Typography } from '@mui/material';

import useTawkTo from '@hook/useTawkTo.hook';

const SupportSection: FC = () => {
  const { maximize: maximizeTawkTo }: any = useTawkTo();

  return (
    <Box mt={4}>
      <Typography variant="h6" gutterBottom>
        Need Help?
      </Typography>
      <Typography variant="body2" gutterBottom>
        Check out our{' '}
        <Link
          onClick={() => {
            window.open('https://www.npmjs.com/package/zinari-pay', '_blank');
          }}
          sx={{ '&:hover': { cursor: 'pointer' } }}
        >
          documentation
        </Link>{' '}
        or{' '}
        <Link
          sx={{ '&:hover': { cursor: 'pointer' } }}
          onClick={maximizeTawkTo}
        >
          contact support
        </Link>{' '}
        if you need any assistance.
      </Typography>
    </Box>
  );
};

export default SupportSection;
