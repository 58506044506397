import {
  Alert,
  AlertTitle,
  Divider,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';

import AppLoader from '@component/AppLoader';
import { useAuth } from '@hook/useAuth.hook';
import BasicInfoSection from '@page/Settings/BasicInfoSection';
import BusinessOwnerForm from '@page/Settings/BusinessOwnerForm';
import { useAppSelector } from '@hook/hooks.hook';
import { selectBusinessOwnerKyc } from '@selector/auth.selector';
import BusinessOwnerKyc from '@interface/businessOwnerKyc.interface';
import { selectIsBusinessOwner } from '@selector/permission.selector';
import CompanyDirectorDetailView from '@component/CompanyDirectorDetailView';
import { CompanyDirector } from '@interface/company.interface';

const Settings = () => {
  const { isAuthenticating } = useAuth();
  const businessOwnerKyc = useAppSelector(selectBusinessOwnerKyc);
  const isBusinessOwner = useAppSelector(selectIsBusinessOwner);
  const isVerified = businessOwnerKyc?.isVerified;
  const verificationRequested = businessOwnerKyc?.verificationRequested;
  const showForm = isBusinessOwner && !isVerified && !verificationRequested;

  return isAuthenticating ? (
    <AppLoader />
  ) : (
    <>
      <Toolbar>
        <Typography variant="h4">Settings</Typography>
      </Toolbar>
      <Stack gap={4}>
        <BasicInfoSection />

        {/* TODO: User should go through forgot password flow to change password*/}
        {/*{!showForm && <ChangePassword />}*/}

        {showForm && (
          <BusinessOwnerForm
            {...(businessOwnerKyc || ({} as BusinessOwnerKyc))}
          />
        )}

        {!showForm && isBusinessOwner && (
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h6" fontWeight="bold">
              Business Owner KYC
            </Typography>

            <Divider variant="fullWidth" sx={{ mt: 2, mb: 3 }} />

            {!isVerified && verificationRequested && (
              <Alert severity="info">
                <AlertTitle sx={{ fontWeight: 'bold' }}>
                  Verification Pending
                </AlertTitle>
                Our team has received your verification request. You will be
                notified once your account is verified.
              </Alert>
            )}

            {isVerified && (
              <Alert severity="success">
                <AlertTitle sx={{ fontWeight: 'bold' }}>Verified</AlertTitle>
                Our team has successfully verified your information. To update
                this information, you&apos;ll need to contact support.
              </Alert>
            )}

            {isBusinessOwner && (
              <CompanyDirectorDetailView
                {...((businessOwnerKyc as unknown as CompanyDirector) ||
                  ({} as CompanyDirector))}
                fullName={`${businessOwnerKyc?.firstName} ${businessOwnerKyc?.lastName}`}
              />
            )}
          </Paper>
        )}

        {/* TODO: User should go through forgot password flow to change password*/}
        {/*{showForm && <ChangePassword />}*/}
      </Stack>
    </>
  );
};

export default Settings;
