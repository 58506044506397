import { Alert, AlertTitle, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';
import { getCountryCallingCode } from 'libphonenumber-js';

import Company from '@interface/company.interface';
import { COMPANY_TYPES, COUNTRY_NAME } from '@variable';
import cld from '@localCloudinary';

const PHONE_NUMBER_LABEL = 'Support Phone Number';
const REGISTRATIONS_NUMBER_LABEL = 'Registration Number';
const COUNTRY_OF_INCORPORATION = 'Country of Incorporation';

const CompanyDetail = ({
  name,
  type,
  countryOfIncorporation,
  supportEmail,
  supportPhoneNumber,
  cacDocUpload,
  verificationRequested,
  isVerified,
}: Company) => {
  const cacImg = cld
    .image(cacDocUpload)
    .format('auto')
    .quality('auto')
    .resize(auto().gravity(autoGravity()));

  const ITEMS = [
    {
      label: 'Name',
      value: name,
    },
    {
      label: 'Type',
      value: COMPANY_TYPES.find(({ value }) => value === type)?.label,
    },
    {
      label: COUNTRY_OF_INCORPORATION,
      value: countryOfIncorporation,
    },
    {
      label: 'Support Email',
      value: supportEmail,
    },
    {
      label: PHONE_NUMBER_LABEL,
      value: supportPhoneNumber,
    },
  ];

  const showVerificationPendingAlert = !isVerified && verificationRequested;

  return (
    <>
      <Typography variant="h6" fontWeight="bold">
        Company Details
      </Typography>
      <Divider
        variant="fullWidth"
        sx={{ mt: 2, mb: showVerificationPendingAlert ? 2 : 4 }}
      />

      {showVerificationPendingAlert && (
        <Alert severity="info" sx={{ mb: 2 }}>
          <AlertTitle sx={{ fontWeight: 'bold' }}>
            Verification Pending
          </AlertTitle>
          Our team has received your verification request. You will be notified
          once your account is verified.
        </Alert>
      )}

      {isVerified && (
        <Alert severity="success">
          <AlertTitle sx={{ fontWeight: 'bold' }}>Verified</AlertTitle>
          Our team has successfully verified your information. To update this
          information, you&apos;ll need to contact support.
        </Alert>
      )}

      <Grid container spacing={3}>
        {ITEMS.map(({ value, label }) => (
          <Grid item xs={12} sm={6} key={label}>
            <Typography variant="caption">{label}</Typography>
            <Typography variant="body1">
              {label === PHONE_NUMBER_LABEL &&
                `+${
                  countryOfIncorporation
                    ? getCountryCallingCode(countryOfIncorporation as any)
                    : ''
                } `}
              {label === REGISTRATIONS_NUMBER_LABEL &&
                countryOfIncorporation === 'NG' &&
                'RC-'}
              {label === COUNTRY_OF_INCORPORATION && value
                ? COUNTRY_NAME[value as string]
                : value}
            </Typography>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Company Registration Document
          </Typography>

          <AdvancedImage
            cldImg={cacImg}
            style={{
              maxWidth: 400,
              height: 'auto',
              width: '100%',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyDetail;
