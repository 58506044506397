import React from 'react';
import { Chip } from '@mui/material';

import { AppTransactionStatus } from '@enum/appTransactionStatus.enum';
import { APP_TRANSACTION_STATUS_COLORS } from '@variable';

const TransactionStatus: React.FC<{
  status: AppTransactionStatus;
}> = ({ status }) => {
  return <Chip label={status} color={APP_TRANSACTION_STATUS_COLORS[status]} />;
};

export default TransactionStatus;
