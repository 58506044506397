import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL, SLICE_NAME } from '@variable';
import AuthResponse from '@interface/authResponse.interface';
import User from '@interface/user.interface';

export const fetchUserData = createAsyncThunk(
  `${SLICE_NAME}/fetchUserData`,
  async (): Promise<AuthResponse> => {
    const result = await axios(`${API_URL}/user`);
    return result.data;
  },
);
export const updateUser = createAsyncThunk(
  `${SLICE_NAME}/updateUser`,
  async (data: User): Promise<AuthResponse> => {
    const result = await axios.put(`${API_URL}/user`, data);
    return result.data;
  },
);
