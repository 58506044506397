import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

import { AppTransactionAttributes } from '@interface/appTransaction.interface';
import TransactionStatus from '@component/TransactionStatus';
import { getCompanyTransactions } from '@action/company.action';
import { useAppDispatch } from '@hook/hooks.hook';
import PaginatedResult from '@interface/paginatedResult.interface';
import { TransactionType } from '@enum/transactionType.enum';

const AppTransactions = () => {
  const [transactions, setTransactions] =
    useState<PaginatedResult<AppTransactionAttributes>>();
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(transactions?.currentPage || 0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const dispatch = useAppDispatch();
  const [searchPerformed, setSearchPerformed] = useState(false);

  const handleSearch = useCallback(
    async (values?: any) => {
      const { newPage, newRowsPerPage } = values || {};
      const res = (await dispatch(
        getCompanyTransactions({
          page: (newPage || page || 0) + 1,
          limit: newRowsPerPage || rowsPerPage,
          searchQuery: searchTerm,
        }),
      )) as any;

      if (!res.error) {
        setTransactions(res.payload);
      }
      setSearchPerformed(true);
    },
    [dispatch, page, rowsPerPage, searchTerm],
  );

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = async (_: unknown, newPage: number) => {
    setPage(newPage);
    await handleSearch({
      page: newPage,
    });
  };

  const handleRowsPerPageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await handleSearch({
      newRowsPerPage,
      page: 0,
    });
  };

  const openBlockchainTransactionDetails = (
    blockchainTransactionId?: string,
  ) => {
    if (!blockchainTransactionId) return;
    window.open(
      'https://tronscan.org/#/transaction/' + blockchainTransactionId,
      '_blank',
    );
  };

  return (
    <>
      <Toolbar sx={{ justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="h4">Manage Transactions</Typography>

        <TextField
          variant="outlined"
          placeholder="Search Transactions"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setSearchPerformed(false);
          }}
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          sx={{ width: '300px' }}
        />
      </Toolbar>

      <Paper sx={{ p: 4, mb: 4 }}>
        {!searchPerformed && (
          <Typography variant="body1" align="center" color="text.secondary">
            Search for a transaction with the <b>blockchain transactionID</b> or{' '}
            <b>Wallet address</b>. Press <b>ENTER</b>
          </Typography>
        )}

        {!transactions?.data?.length && searchPerformed && (
          <Box p={2}>
            <Typography variant="body1" align="center" color="text.secondary">
              No results {searchTerm && <>for &quot;{searchTerm}&quot;</>}
            </Typography>
          </Box>
        )}

        {searchPerformed &&
          Boolean(searchTerm) &&
          Boolean(transactions?.data?.length) && (
            <Box p={2}>
              <Typography>
                Search result(s) for &quot;<b>{searchTerm}</b>&quot;
              </Typography>
            </Box>
          )}

        {Boolean(transactions?.data?.length) && searchPerformed && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>App Name</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {transactions?.data.map(
                  ({
                    blockchainTransactionId,
                    appName,
                    cryptocurrency,
                    cryptocurrencyAmount,
                    createdAt,
                    status,
                    type,
                    id,
                  }) => (
                    <TableRow
                      key={id}
                      onClick={() =>
                        openBlockchainTransactionDetails(
                          blockchainTransactionId,
                        )
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        <Tooltip title={type} arrow placement="right">
                          {type === TransactionType.INCOMING ? (
                            <ArrowDownward color="success" />
                          ) : (
                            <ArrowUpward color="warning" />
                          )}
                        </Tooltip>
                      </TableCell>
                      <TableCell>{appName}</TableCell>
                      <TableCell>
                        {cryptocurrencyAmount} {cryptocurrency}
                      </TableCell>
                      <TableCell>
                        {dayjs(createdAt).format('hh:mm A MMMM D, YYYY')}
                      </TableCell>
                      <TableCell>
                        <TransactionStatus status={status} />
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={transactions?.data?.length || 0}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPageOptions={[30, 60, 100]}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              slotProps={{
                actions: {
                  previousButton: { 'aria-label': 'Previous Page' },
                  nextButton: { 'aria-label': 'Next Page' },
                },
              }}
            />
          </TableContainer>
        )}
      </Paper>
    </>
  );
};

export default AppTransactions;
