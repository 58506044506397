import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';

import cld from '@localCloudinary';
import TaxAndSecurity from '@component/AppDetail/TaxAndSecurity';
import App from '@interface/app.interface';
import { AppMode } from '@enum/appMode.enum';
import { toggleAppMode } from '@action/app.action';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectIsUpdatingApp } from '@selector/app.selector';
import { selectCompanyInfo } from '@selector/company.selector';
import PayLink from '@component/AppDetail/PayLink';

import BasicInfo from './BasicInfo';
import AppWallet from './AppWallet';
import ApiInfo from './ApiInfo';

const TABS = ['Basic Info', 'API', 'App Wallet', 'Fees & Security', 'PayLink'];

const AppDetail: FC<App> = ({
  id,
  mode,
  name,
  logoUrl,
  appWallets: wallets,
  appCurrencies,
  publicKey,
  transactionDetectedWebhookUrl,
  transactionDoneWebhookUrl,
  incompletePaymentCompletionWebhookUrl,
  customerPaysTax,
  whitelistedUrls,
  customerPaysFees,
  fiatCurrency,
  apiKey,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsTab = Number(searchParams.get('tab') || 0);
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(searchParamsTab);
  const theme: any = useTheme();
  const company = useAppSelector(selectCompanyInfo);
  useEffect(() => {
    setSearchParams({ tab: String(value) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const isUpdatingApp = useAppSelector(selectIsUpdatingApp);

  const dispatch = useAppDispatch();

  const handleModeChange = () => {
    if (!isUpdatingApp) {
      dispatch(toggleAppMode(id));
    }
  };

  const inProduction = mode === AppMode.PRODUCTION;

  const img = cld
    .image(logoUrl)
    .format('auto')
    .quality('auto')
    .resize(auto().gravity(autoGravity()).width(20).height(20));

  const switchStyles = {
    '& .MuiSwitch-switchBase, & .MuiSwitch-switchBase.Mui-checked': {
      color: theme.palette[inProduction ? 'success' : 'error'].main,
      '&:hover': {
        backgroundColor: inProduction
          ? 'rgba(0, 255, 0, 0.08)'
          : 'rgba(255, 0, 0, 0.08)',
      },
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track, & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
      {
        backgroundColor: theme.palette[inProduction ? 'success' : 'error'].main,
      },
  };

  return (
    <>
      <Toolbar>
        <Stack
          direction="row"
          marginBottom={1}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() => navigate('/dashboard/apps')}
          >
            Go Back
          </Button>

          <FormControlLabel
            disabled={isUpdatingApp}
            control={
              <Switch
                checked={inProduction}
                onChange={handleModeChange}
                sx={switchStyles}
                disabled={isUpdatingApp}
              />
            }
            label={inProduction ? 'In Production' : 'In Development'}
          />

          <Stack
            gap={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <AdvancedImage cldImg={img} />
            <Typography variant="h6" fontWeight="bold">
              {name} ({fiatCurrency})
            </Typography>
          </Stack>
        </Stack>
      </Toolbar>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="app detail tabs"
          sx={{ background: 'white' }}
          scrollButtons="auto"
          visibleScrollbar
        >
          {TABS.map((tab) => (
            <Tab label={tab} key={tab} />
          ))}
        </Tabs>
      </Box>

      <BasicInfo
        id={id}
        name={name}
        value={value}
        logoUrl={logoUrl}
        index={0}
      />

      <ApiInfo
        id={id}
        value={value}
        publicKey={publicKey}
        transactionDoneWebhookUrl={transactionDoneWebhookUrl}
        transactionDetectedWebhookUrl={transactionDetectedWebhookUrl}
        incompletePaymentCompletionWebhookUrl={
          incompletePaymentCompletionWebhookUrl
        }
        appCurrencies={appCurrencies}
        fiatCurrency={fiatCurrency}
        whitelistedUrls={whitelistedUrls}
        apiKey={apiKey}
        index={1}
      />

      <AppWallet value={value} wallets={wallets} index={2} appId={id} />

      <TaxAndSecurity
        id={id}
        index={3}
        value={value}
        customerPaysTax={customerPaysTax}
        customerPaysFees={customerPaysFees}
        taxRate={company.taxRate as number}
        feeRate={company.feeRate as number}
      />

      <PayLink
        index={4}
        value={value}
        appId={id}
        fiatCurrency={fiatCurrency}
        inProduction={inProduction}
      />
    </>
  );
};

export default AppDetail;
