import React, { useCallback, useEffect, useState } from 'react';
import {
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';

import CompanyInfoForm from '@component/CompanyInfoForm';
import CompanyDirectorsSection from '@component/CompanyDirectorsSection';
import { getCompanyDetails, updateCompany } from '@action/company.action';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import {
  selectCompanyInfo,
  selectIsLoadingCompany,
} from '@selector/company.selector';
import CompanyDetail from '@page/Company/CompanyDetails';
import {
  selectCanEditCompanyDetails,
  selectCanViewCompanyDirectors,
} from '@selector/permission.selector';
import Company from '@interface/company.interface';
import { selectBusinessOwnerKyc } from '@selector/auth.selector';

const CompanyManagementPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompanyInfo);
  const isLoadingCompany = useAppSelector(selectIsLoadingCompany);
  const canViewCompanyDirectors = useAppSelector(selectCanViewCompanyDirectors);
  const canEditCompanyDetails = useAppSelector(selectCanEditCompanyDetails);
  const [hasFetchedCompanyDirectors, setHasFetchedCompanyDirectors] =
    useState(false);
  const businessOwnerKyc = useAppSelector(selectBusinessOwnerKyc);

  useEffect(() => {
    if (canViewCompanyDirectors && !hasFetchedCompanyDirectors) {
      setHasFetchedCompanyDirectors(true);
      dispatch(getCompanyDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canViewCompanyDirectors, hasFetchedCompanyDirectors]);

  const updateCompanyRegistrationStatus = useCallback(
    (_: any, isRegistered: boolean) => {
      dispatch(updateCompany({ isRegistered } as Company));
    },
    [dispatch],
  );

  const businessOwnerKycIsVerified =
    !company?.isRegistered && businessOwnerKyc.isVerified;
  const showIsRegisteredToggle =
    !businessOwnerKycIsVerified &&
    !company.isVerified &&
    !company.verificationRequested &&
    canEditCompanyDetails;

  return (
    <>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h4" gutterBottom>
          Company Management
        </Typography>

        {showIsRegisteredToggle && (
          <FormControlLabel
            control={
              <Switch
                disabled={isLoadingCompany}
                checked={company?.isRegistered}
                onChange={updateCompanyRegistrationStatus}
              />
            }
            label={
              company?.isRegistered
                ? 'Registered Company'
                : 'Unregistered Company'
            }
          />
        )}
      </Toolbar>

      {company?.isRegistered ? (
        <Stack gap={4}>
          <Paper sx={{ p: 4 }}>
            {company.isVerified ||
            company.verificationRequested ||
            !canEditCompanyDetails ? (
              <CompanyDetail {...company} />
            ) : (
              <CompanyInfoForm {...company} />
            )}
          </Paper>

          {canViewCompanyDirectors && (
            <Paper sx={{ p: 4 }}>
              <CompanyDirectorsSection />
            </Paper>
          )}
        </Stack>
      ) : (
        <Stack gap={4}>
          <Paper sx={{ p: 4 }}>
            <Typography variant="body1">
              You run an unregistered entity
            </Typography>
            {!showIsRegisteredToggle && (
              <Typography variant="caption">
                To switch to a <b>Registered Entity</b> please contact support
                via the chatbot, or send an email to support@zinari.io
              </Typography>
            )}
          </Paper>
        </Stack>
      )}
    </>
  );
};

export default CompanyManagementPage;
