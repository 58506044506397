import React, { ReactNode, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';

interface ConfirmationDialogProps {
  title: string | ReactNode;
  subtitle: string | ReactNode;
  onConfirm: () => any | Promise<any>;
  open: boolean;
  onClose: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  subtitle,
  onConfirm,
  open,
  onClose,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await onConfirm();
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle
        id="confirmation-dialog-title"
        style={{ textAlign: 'center', fontWeight: 'bold' }}
      >
        {title}
      </DialogTitle>

      <Divider />

      <DialogContent>
        <DialogContentText
          id="confirmation-dialog-description"
          style={{ textAlign: 'center' }}
        >
          {subtitle}
        </DialogContentText>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'center', py: 2 }}>
        <Button
          onClick={onClose}
          variant="contained"
          color="error"
          disabled={loading}
        >
          {cancelButtonText}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
