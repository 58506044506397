import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import SendIcon from '@mui/icons-material/Send';

import { DirectorCardProps } from '@interface/company.interface';
import ConfirmationDialog from '@component/ConfirmationDialog';
import {
  deleteCompanyDirector,
  requestCompanyDirectorVerification,
} from '@action/company.action';
import { useAppDispatch } from '@hook/hooks.hook';

const FIELDS = [
  'fullName',
  'dateOfBirth',
  'nationality',
  'phoneNumber',
  'countryOfResidence',
  'email',
  'idUpload',
  'state',
  'street',
  'city',
  'utilityBillUpload',
];

const DirectorCard: React.FC<DirectorCardProps> = ({ onEdit, director }) => {
  const dispatch = useAppDispatch();
  const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);
  const [deletionDialogOpen, setDeletionDialogOpen] = useState(false);

  const incompleteInfo = ((): boolean =>
    FIELDS.some((field) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return !director[field];
    }))();

  const canBeSubmitted =
    !incompleteInfo && !director.isVerified && !director.verificationRequested;

  const handleSubmitForVerification = () => {
    dispatch(requestCompanyDirectorVerification(director?.id));
  };

  const handleDelete = () => {
    dispatch(deleteCompanyDirector(director?.id));
  };

  const onOpenSubmissionDialog = () => {
    setSubmissionDialogOpen(true);
  };

  const onOpenDeletionDialogOpen = () => {
    setDeletionDialogOpen(true);
  };

  const confirmationDialogProps = {
    open: submissionDialogOpen || deletionDialogOpen,
    title: submissionDialogOpen ? 'Submitting Director' : 'Deleting Director',
    subtitle: submissionDialogOpen ? (
      <>
        <div>
          Are you sure you want to submit <b>{director?.fullName}&apos;s</b>{' '}
          details for verification?
        </div>
        <div>You can not undo this action once it&apos;s done</div>
      </>
    ) : (
      <>
        <div>
          Are you sure you want to delete <b>{director?.fullName}&apos;s</b>?
        </div>
        <div>You can not undo this action once it&apos;s done</div>
      </>
    ),
    onClose: () => {
      const close = submissionDialogOpen
        ? setSubmissionDialogOpen
        : setDeletionDialogOpen;
      close(false);
    },
    onConfirm: submissionDialogOpen
      ? handleSubmitForVerification
      : handleDelete,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  };

  return (
    <>
      <Card variant="outlined" style={{ marginBottom: 16, height: '100%' }}>
        <CardContent sx={{ position: 'relative', height: '100%' }}>
          <Typography variant="h6">{director.fullName}</Typography>

          {!incompleteInfo && Boolean(director.email) && (
            <Typography variant="body2">Email: {director.email} </Typography>
          )}

          {!incompleteInfo && director.phoneNumber && (
            <Typography variant="body2">
              Phone: +234 {director.phoneNumber}
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 16,
            }}
          >
            <Tooltip
              title={
                !director?.isVerified && !director.verificationRequested
                  ? 'Edit'
                  : 'View'
              }
            >
              <IconButton onClick={() => onEdit(director)}>
                {!director?.isVerified && !director.verificationRequested ? (
                  <EditIcon color="info" />
                ) : (
                  <VisibilityRoundedIcon color="primary" />
                )}
              </IconButton>
            </Tooltip>

            {canBeSubmitted && (
              <Tooltip title="Submit for verification">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={onOpenSubmissionDialog}
                >
                  <SendIcon />
                </IconButton>
              </Tooltip>
            )}

            {!director?.isVerified && !director.verificationRequested && (
              <Tooltip title="Delete">
                <IconButton
                  onClick={onOpenDeletionDialogOpen}
                  color={'primary'}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </CardContent>
      </Card>

      <ConfirmationDialog {...confirmationDialogProps} />
    </>
  );
};

export default DirectorCard;
