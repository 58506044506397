import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { selectApp, selectIsLoadingApps } from '@selector/app.selector';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import AppDetail from '@component/AppDetail';
import { fetchApps } from '@action/app.action';
import AppLoader from '@component/AppLoader';

const ViewCreatedApp = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const app = useAppSelector((state) => selectApp(state, id as string));
  const isLoadingApps = useAppSelector(selectIsLoadingApps);

  useEffect(() => {
    if (!app) dispatch(fetchApps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingApps) return <AppLoader />;

  if (!app) return <Typography>App Not Found</Typography>;

  return <AppDetail {...app} />;
};

export default ViewCreatedApp;
