import React from 'react';
import { Button, Container } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const LoginForm: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <Container
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        width: '100vw',
      }}
    >
      <Button
        color={'primary'}
        onClick={handleLogin}
        variant="contained"
        sx={{ textTransform: 'none' }}
      >
        Enter ZinariPay Console
      </Button>
    </Container>
  );
};

export default LoginForm;
