import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React, { FC, useState } from 'react';
import isEqual from 'lodash.isequal';

import { COMPANY_TYPES } from '@variable';
import FileUploadField from '@component/DirectorDrawerForm/FileUploadField';
import LoadingButton from '@component/LoadingButton';
import Company from '@interface/company.interface';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectIsUpdatingCompany } from '@selector/company.selector';
import ConfirmationDialog from '@component/ConfirmationDialog';
import { requestCompanyVerification } from '@action/company.action';
import PhoneNumberField from '@component/PhoneNumberField';
import CountrySelectField from '@component/CountrySelectField';

interface CompanyFormProps extends Company {
  initialValues: Company;
}

const CompanyForm: FC<CompanyFormProps> = ({
  name,
  type,
  countryOfIncorporation,
  supportEmail,
  cacDocUpload,
  supportPhoneNumber,
  initialValues,
}) => {
  const { errors, touched, isValid, dirty, values } =
    useFormikContext<Company>();
  const isUpdatingCompany = useAppSelector(selectIsUpdatingCompany);
  const [openConfirmRequestVerification, setOpenConfirmRequestVerification] =
    useState(false);
  const dispatch = useAppDispatch();

  const formValues = {
    name: values.name,
    type: values.type,
    countryOfIncorporation: values.countryOfIncorporation,
    supportEmail: values.supportEmail,
    cacDocUpload: values.cacDocUpload,
    supportPhoneNumber: values.supportPhoneNumber,
  };

  const showRequestVerificationButton =
    isEqual(initialValues, formValues) &&
    Boolean(name) &&
    Boolean(type) &&
    Boolean(countryOfIncorporation) &&
    Boolean(supportEmail) &&
    Boolean(cacDocUpload) &&
    Boolean(supportPhoneNumber);

  const handleConfirmRequestVerification = () => {
    dispatch(requestCompanyVerification());
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" fontWeight="bold">
          Company Information
        </Typography>
      </Box>
      <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />

      {showRequestVerificationButton && (
        <Alert severity="info" sx={{ mb: 4 }}>
          <AlertTitle sx={{ fontWeight: 'bold' }}>
            Request Verification
          </AlertTitle>
          Please review your details and click the &quot;Request
          Verification&quot; button to request verification
        </Alert>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box mb={2}>
            <Field
              as={TextField}
              name="name"
              label="Company Name"
              fullWidth
              variant="outlined"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box mb={2}>
            <Field
              as={TextField}
              select
              name="type"
              label="Type"
              fullWidth
              variant="outlined"
              error={touched.type && !!errors.type}
              helperText={touched.type && errors.type}
            >
              {COMPANY_TYPES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box mb={2}>
            <Field
              as={TextField}
              name="supportEmail"
              label="Support Email"
              fullWidth
              variant="outlined"
              error={touched.supportEmail && !!errors.supportEmail}
              helperText={touched.supportEmail && errors.supportEmail}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box mb={2}>
            <PhoneNumberField
              as={TextField}
              name="supportPhoneNumber"
              label="Support Phone Number"
              fullWidth
              country={countryOfIncorporation}
              variant="outlined"
              error={touched.supportPhoneNumber && !!errors.supportPhoneNumber}
              helperText={
                touched.supportPhoneNumber && errors.supportPhoneNumber
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box mb={2}>
            <CountrySelectField
              name="countryOfIncorporation"
              label="Country of Incorporation"
              fullWidth
              variant="outlined"
              error={
                touched.countryOfIncorporation &&
                !!errors.countryOfIncorporation
              }
              helperText={
                touched.countryOfIncorporation && errors.countryOfIncorporation
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mb={2}>
            <FileUploadField
              label="Upload your Company Registration Document"
              name="cacDocUpload"
              uwConfig={{
                folder: 'company_registration',
                tags: ['apps', 'company', 'verification'],
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt={3} display="flex" width="100%" justifyContent="flex-end">
        {showRequestVerificationButton ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setOpenConfirmRequestVerification(true);
            }}
          >
            Request Verification
          </Button>
        ) : (
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            isLoading={isUpdatingCompany}
            disabled={!isValid || !dirty}
          >
            Save
          </LoadingButton>
        )}
      </Box>
      {openConfirmRequestVerification && (
        <ConfirmationDialog
          title="Request Verification"
          subtitle={
            'Ensure you have checked all the details before you submit this form'
          }
          open={openConfirmRequestVerification}
          onClose={() => {
            setOpenConfirmRequestVerification(false);
          }}
          onConfirm={handleConfirmRequestVerification}
          confirmButtonText="Submit"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default CompanyForm;
