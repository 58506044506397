import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Toolbar, Typography } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { fetchApps } from '@action/app.action';
import AppCard from '@component/AppCard';
import { selectApps, selectIsLoadingApps } from '@selector/app.selector';
import AppLoader from '@component/AppLoader';
import CreateAppDialog from '@component/CreateAppDialog';

const ViewApps = () => {
  const dispatch = useAppDispatch();
  const apps = useAppSelector(selectApps);
  const isLoadingApps = useAppSelector(selectIsLoadingApps);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  useEffect(() => {
    dispatch(fetchApps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleOpenCreateDialog = () => {
    setOpenCreateDialog((value) => !value);
  };

  return isLoadingApps && !apps ? (
    <AppLoader />
  ) : (
    <>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h4" gutterBottom>
          Apps
        </Typography>

        <Button
          color="primary"
          onClick={toggleOpenCreateDialog}
          startIcon={<AddCircleOutline />}
          variant="contained"
        >
          Add new app
        </Button>
      </Toolbar>

      <Paper sx={{ p: 4 }}>
        <Grid container spacing={3}>
          {apps.map((app) => (
            <Grid item key={app.id} xs={12} sm={6} md={4}>
              <AppCard {...app} />
            </Grid>
          ))}
        </Grid>

        {!apps.length && !isLoadingApps && (
          <Box pt={2}>
            <Typography variant="body1">You have not added any app</Typography>
          </Box>
        )}

        {isLoadingApps && (
          <Box p={4}>
            <AppLoader size={8} />
          </Box>
        )}
      </Paper>
      <CreateAppDialog
        open={openCreateDialog}
        onClose={toggleOpenCreateDialog}
      />
    </>
  );
};

export default ViewApps;
