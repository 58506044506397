import React, { useState } from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SendRoundedIcon from '@mui/icons-material/SendRounded';

import TabPanel from '@component/AppDetail/TabPanel';
import { createPaymentLink } from '@action/app.action';
import { useAppDispatch } from '@hook/hooks.hook';
import CopyToClipboard from '@component/CopyToClipboard';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Email is required'),
  amount: Yup.number()
    .required('Amount is required')
    .positive('Amount must be greater than zero'),
  extraDetails: Yup.array().of(
    Yup.object({
      key: Yup.string().required('Key is required'),
      value: Yup.string().required('Value is required'),
    }),
  ),
});

const PayLink = ({
  index,
  value,
  appId,
  fiatCurrency,
  inProduction,
}: {
  index: number;
  value: any;
  appId: string;
  fiatCurrency: string;
  inProduction: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [extraDetails, setExtraDetails] = useState<
    { key: string; value: string }[]
  >([]);
  const [paymentLink, setPaymentLink] = useState('');

  const formik: any = useFormik({
    initialValues: {
      email: '',
      amount: '',
      extraDetails: [] as any[],
    },
    validationSchema,
    onSubmit: async ({ extraDetails, ...values }) => {
      const extras = extraDetails.reduce((memo, { key, value }) => {
        memo[key] = value;
        return memo;
      }, {});
      const res: any = await dispatch(
        createPaymentLink({ appId, ...values, ...extras }),
      );

      if (!res.error) {
        formik.resetForm();
        setExtraDetails([]);
        setPaymentLink(res.payload);
      }
    },
  });

  const handleAddExtraDetail = () => {
    setExtraDetails([...extraDetails, { key: '', value: '' }]);
  };

  const handleRemoveExtraDetail = (index: number) => {
    const updatedExtraDetails = extraDetails.filter((_, i) => i !== index);
    setExtraDetails(updatedExtraDetails);
  };

  return (
    <TabPanel value={value} index={index}>
      <form onSubmit={formik.handleSubmit}>
        {!inProduction && (
          <Alert severity="info">
            <AlertTitle>
              This feature can only be used in production mode.
            </AlertTitle>
          </Alert>
        )}

        <Typography variant="h6" color="primary" fontWeight="bold">
          Create a payment link
        </Typography>
        <Typography mb={2} variant="subtitle1">
          Quickly create and send payment links, allowing your customers to
          complete transactions with ease.
        </Typography>

        <Stack spacing={2} maxWidth="sm">
          {/* Email Field */}
          <Grid container justifyContent="space-between" gap={2}>
            <Grid item md={6}>
              <TextField
                fullWidth
                label="Customer Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={!inProduction}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>

            {/* Amount Field */}
            <Grid item md={6}>
              <TextField
                fullWidth
                label="Amount"
                name="amount"
                type="number"
                disabled={!inProduction}
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {fiatCurrency}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {/* Extra Details Section */}
          <Typography variant="h6" mt={2}>
            Extra Details (Optional)
          </Typography>
          <Typography variant="body2" color="textSecondary" mb={2}>
            Add any additional details for your transaction (key-value pairs).
          </Typography>

          {extraDetails.map((detail, index) => (
            <Stack
              maxWidth={'md'}
              key={index}
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <TextField
                fullWidth
                label={`Key ${index + 1}`}
                name={`extraDetails[${index}].key`}
                value={detail.key}
                onChange={(e) => {
                  const updatedDetails = [...extraDetails];
                  updatedDetails[index].key = e.target.value;
                  setExtraDetails(updatedDetails);
                  formik.setFieldValue(
                    `extraDetails[${index}].key`,
                    e.target.value,
                  );
                }}
                error={
                  formik.touched.extraDetails?.[index]?.key &&
                  Boolean(formik.errors.extraDetails?.[index]?.key)
                }
                helperText={
                  formik.touched.extraDetails?.[index]?.key &&
                  formik.errors.extraDetails?.[index]?.key
                }
              />
              <TextField
                fullWidth
                label={`Value ${index + 1}`}
                name={`extraDetails[${index}].value`}
                value={detail.value}
                onChange={(e) => {
                  const updatedDetails = [...extraDetails];
                  updatedDetails[index].value = e.target.value;
                  setExtraDetails(updatedDetails);
                  formik.setFieldValue(
                    `extraDetails[${index}].value`,
                    e.target.value,
                  );
                }}
                error={
                  formik.touched.extraDetails?.[index]?.value &&
                  Boolean(formik.errors.extraDetails?.[index]?.value)
                }
                helperText={
                  formik.touched.extraDetails?.[index]?.value &&
                  formik.errors.extraDetails?.[index]?.value
                }
              />
              <IconButton onClick={() => handleRemoveExtraDetail(index)}>
                <RemoveIcon />
              </IconButton>
            </Stack>
          ))}

          <Stack spacing={2}>
            {/* Add Extra Detail Button */}
            <Button
              sx={{ maxWidth: 545 }}
              startIcon={<AddIcon />}
              onClick={handleAddExtraDetail}
              variant="outlined"
              color="primary"
              size={'small'}
              disabled={!inProduction}
            >
              Add Extra Detail
            </Button>

            {/* Submit Button */}
            <Button
              sx={{ maxWidth: 300 }}
              size={'small'}
              type="submit"
              variant="contained"
              color="primary"
              disabled={!inProduction || !formik.isValid || formik.isSubmitting}
              endIcon={<SendRoundedIcon />}
            >
              Send Payment link
            </Button>
            {Boolean(paymentLink) && (
              <>
                <Alert severity="success">
                  <Typography>
                    {paymentLink.substring(0, 50)}... (click copy below)
                  </Typography>
                  <CopyToClipboard value={paymentLink} />
                </Alert>
              </>
            )}
          </Stack>
        </Stack>
      </form>
    </TabPanel>
  );
};

export default PayLink;
