import { Container } from '@mui/material';
import React from 'react';

import Spinner from '@component/Spinner';
import { SpinnerVariant } from '@component/Spinner/Spinner';

const AppLoader = ({ size }: { size?: number }) => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: size || '80vh',
        justifyContent: 'center',
      }}
    >
      <Spinner variant={SpinnerVariant.WAVE} size={size} />
    </Container>
  );
};

export default AppLoader;
