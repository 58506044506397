import React, { FC, useCallback, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';

import cld from '@localCloudinary';
import TabPanel from '@component/AppDetail/TabPanel';
import CloudinaryUploadWidget from '@component/CloudinaryUploadWidgets';
import { useAppDispatch } from '@hook/hooks.hook';
import { updateApp } from '@action/app.action';
import App from '@interface/app.interface';

interface BasicInfoProps {
  id: string;
  name: string;
  logoUrl: string;
  value: number;
  index: number;
}

const validationSchema = Yup.object({
  name: Yup.string().required('App name is required'),
  logo: Yup.string(),
});

const BasicInfo: FC<BasicInfoProps> = ({ id, name, value, logoUrl, index }) => {
  const [publicId, setPublicId] = useState(logoUrl);
  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    async (values: any) => {
      await dispatch(
        updateApp({ id, name: values.name, logoUrl: values.logo } as App),
      );
    },
    [dispatch, id],
  );

  const formik = useFormik({
    initialValues: { name, logo: logoUrl },
    validationSchema,
    onSubmit,
  });

  const handleSetPublicId = (publicId: string) => {
    setPublicId(publicId);
    formik.setFieldValue('logo', publicId);
  };

  const img = cld
    .image(publicId)
    .format('auto')
    .quality('auto')
    .resize(auto().gravity(autoGravity()).width(150).height(150));

  return (
    <TabPanel value={value} index={index}>
      <form onSubmit={formik.handleSubmit}>
        <Stack>
          <Typography variant="h6" color="primary" fontWeight="bold">
            Application Name
          </Typography>
          <Typography mb={2} variant="subtitle1">
            This name will be displayed to your customers at payment time
          </Typography>
        </Stack>

        <Stack gap={2} alignItems="flex-start">
          <TextField
            required
            name="name"
            label="App Name"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            sx={{ width: '100%', maxWidth: 400 }}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ''
            }
          />
          <Button
            type="submit"
            variant="contained"
            disabled={!formik.values.name || formik.values.name === name}
          >
            Save Name
          </Button>

          <Divider sx={{ width: '100%' }} />

          <Stack>
            <Typography variant="h6" color="primary" fontWeight="bold">
              Application Logo
            </Typography>
            <Typography variant="subtitle1" mb={1}>
              This logo will be displayed to your customers at payment time
            </Typography>
          </Stack>

          <CloudinaryUploadWidget setPublicId={handleSetPublicId}>
            {Boolean(publicId) && (
              <Box height={150} width={150}>
                <AdvancedImage cldImg={img} />
              </Box>
            )}
          </CloudinaryUploadWidget>

          <Alert color="info">
            <Stack>
              <Typography variant="caption">- Maximum size is 1MB.</Typography>
              <Typography variant="caption">
                - Only PNG, JPEG, JPG and WebP are allowed.
              </Typography>
            </Stack>
          </Alert>

          {formik.touched.logo && formik.errors.logo && (
            <Typography color="error">{formik.errors.logo}</Typography>
          )}

          <Button
            variant="contained"
            disabled={!formik.values.logo || formik.values.logo === logoUrl}
            type="submit"
          >
            Save Logo
          </Button>
        </Stack>
      </form>
    </TabPanel>
  );
};

export default BasicInfo;
