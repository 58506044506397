import React, { FC, useCallback, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import TabPanel from '@component/AppDetail/TabPanel';
import { useAppDispatch } from '@hook/hooks.hook';
import { deleteApp, updateApp } from '@action/app.action';
import App from '@interface/app.interface';
import ConfirmationDialog from '@component/ConfirmationDialog';

interface TaxAndSecurityProps {
  value: number;
  index: number;
  id: string;

  customerPaysTax: boolean;
  customerPaysFees: boolean;
  taxRate: number;
  feeRate: number;
}

const TaxAndSecurity: FC<TaxAndSecurityProps> = ({
  id,
  index,
  taxRate,
  feeRate,
  value,
  customerPaysTax: initialCustomerPaysTax,
  customerPaysFees: initialCustomerPaysFees,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [customerPaysTax, setCustomerPaysTax] = useState<boolean>(
    initialCustomerPaysTax,
  );
  const [customerPaysFees, setCustomerPaysFees] = useState<boolean>(
    initialCustomerPaysFees,
  );
  const [openDeletionDialog, setOpenDeletionDialog] = useState<boolean>(false);

  const isPayingTaxes = (taxRate || 0) > 0;

  const handleConfirmDeleteApp = async () => {
    const res = (await dispatch(deleteApp(id))) as any;
    if (!res.error) navigate('/dashboard/apps');
  };

  const confirmationDialogProps = {
    open: openDeletionDialog,
    title: 'Delete Application',
    subtitle: (
      <>
        <div>Are you sure you want to delete this application</div>
        <div>You can not undo this action once it&apos;s done</div>
      </>
    ),
    onClose: () => {
      setOpenDeletionDialog(false);
    },
    onConfirm: handleConfirmDeleteApp,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  };

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      await dispatch(
        updateApp({
          id,
          customerPaysTax,
          customerPaysFees,
        } as App),
      );
    },
    [customerPaysFees, customerPaysTax, dispatch, id],
  );

  const disableTaxButton =
    customerPaysTax === initialCustomerPaysTax &&
    customerPaysFees === initialCustomerPaysFees;

  return (
    <TabPanel value={value} index={index}>
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          {/* Tax Options Section */}
          <Box>
            <Stack>
              <Typography variant="h6" fontWeight="bold" color="primary">
                Fees
              </Typography>
              <Typography variant="subtitle1">
                This is how we will calculate the fees for each transaction
              </Typography>
            </Stack>

            <Stack spacing={2} mt={2}>
              {isPayingTaxes && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={customerPaysTax}
                      onChange={(e) => setCustomerPaysTax(e.target.checked)}
                    />
                  }
                  label={
                    <>
                      <Typography>Customer Pays Tax?</Typography>
                      <Alert color="warning">
                        {customerPaysTax
                          ? `We will charge the customer an additional ${(
                              taxRate * 100
                            ).toLocaleString('en-US', {
                              maximumFractionDigits: 2,
                            })}% for VAT on top of the total amount you charged`
                          : `We will deduct the ${(
                              taxRate * 100
                            ).toLocaleString('en-US', {
                              maximumFractionDigits: 2,
                            })}% VAT from the total amount you charged the customer`}
                      </Alert>
                    </>
                  }
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    checked={customerPaysFees}
                    onChange={(e) => setCustomerPaysFees(e.target.checked)}
                  />
                }
                label={
                  <>
                    <Typography>Customer Pays Fees?</Typography>
                    <Alert color="warning">
                      {customerPaysFees
                        ? `We will charge the customer the additional ${
                            feeRate * 100
                          }% fees`
                        : `We will charge you the additional ${
                            feeRate * 100
                          }% fees`}
                    </Alert>
                  </>
                }
              />
            </Stack>
          </Box>
          <Button
            variant="contained"
            type="submit"
            disabled={disableTaxButton}
            sx={{ width: 'fit-content' }}
          >
            Save Settings
          </Button>

          <Divider />

          <Box
            sx={{
              p: 4,
              borderRadius: 2,
              boxShadow: 3,
              backgroundColor: 'background.paper',
              maxWidth: '400px',
              mx: 'auto',
            }}
          >
            <Stack spacing={2}>
              <Typography variant="h6" fontWeight="bold" color="primary">
                Delete Application
              </Typography>

              <Typography variant="subtitle1" color="text.secondary">
                Deleting this application will remove all data associated with
                it. This action cannot be undone.
              </Typography>

              <Button
                sx={{
                  width: 'fit-content',
                  borderRadius: 2,
                  boxShadow: 2,
                  ':hover': {
                    boxShadow: 4,
                  },
                }}
                variant="contained"
                color="error"
                size="large"
                onClick={() => {
                  setOpenDeletionDialog(true);
                }}
              >
                Delete
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>

      {openDeletionDialog && (
        <ConfirmationDialog {...confirmationDialogProps} />
      )}
    </TabPanel>
  );
};

export default TaxAndSecurity;
