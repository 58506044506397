import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';

import { HTTPS_WEBHOOK_URL } from '@variable';
import { updateApp } from '@action/app.action';
import { useAppDispatch } from '@hook/hooks.hook';
import App from '@interface/app.interface';

interface WebhookSectionProps {
  id: string;
  transactionDetectedWebhookUrl?: string;
  transactionDoneWebhookUrl?: string;
  incompletePaymentCompletionWebhookUrl?: string;
}

const WebhookSection: FC<WebhookSectionProps> = ({
  id,
  transactionDetectedWebhookUrl: defaultTransactionDetectedWebhookUrl,
  transactionDoneWebhookUrl: defaultTransactionDoneWebhookUrl,
  incompletePaymentCompletionWebhookUrl:
    defaultIncompletePaymentCompletionWebhookUrl,
}) => {
  const dispatch = useAppDispatch();
  const [transactionDoneWebhookUrl, setTransactionDoneWebhookUrl] = useState(
    defaultTransactionDoneWebhookUrl || '',
  );
  const [transactionDetectedWebhookUrl, setTransactionDetectedWebhookUrl] =
    useState(defaultTransactionDetectedWebhookUrl || '');
  const [
    incompletePaymentCompletionWebhookUrl,
    setIncompletePaymentCompletionWebhookUrl,
  ] = useState(defaultIncompletePaymentCompletionWebhookUrl || '');

  const handleWebhookUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newUrl = event.target.value;
    setTransactionDoneWebhookUrl(newUrl);
  };

  const isUrlValid = useCallback((url: string, defaultValue?: string) => {
    return (
      !url ||
      url === defaultValue ||
      (url !== defaultValue && HTTPS_WEBHOOK_URL.test(url))
    );
  }, []);

  const allValuesSame =
    defaultTransactionDoneWebhookUrl === transactionDoneWebhookUrl &&
    defaultTransactionDetectedWebhookUrl === transactionDetectedWebhookUrl &&
    defaultIncompletePaymentCompletionWebhookUrl ===
      incompletePaymentCompletionWebhookUrl;

  const urlInvalid =
    !isUrlValid(transactionDoneWebhookUrl, defaultTransactionDoneWebhookUrl) ||
    !isUrlValid(
      transactionDetectedWebhookUrl,
      defaultTransactionDetectedWebhookUrl,
    ) ||
    !isUrlValid(
      incompletePaymentCompletionWebhookUrl,
      defaultIncompletePaymentCompletionWebhookUrl,
    );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!urlInvalid) {
        await dispatch(
          updateApp({
            id,
            transactionDoneWebhookUrl,
            transactionDetectedWebhookUrl,
            incompletePaymentCompletionWebhookUrl,
          } as App),
        );
      }
    },
    [
      id,
      dispatch,
      urlInvalid,
      transactionDoneWebhookUrl,
      transactionDetectedWebhookUrl,
      incompletePaymentCompletionWebhookUrl,
    ],
  );

  return (
    <>
      <Stack>
        <Typography variant="h6" fontWeight="bold" color="primary">
          Configure Webhook URLs
        </Typography>
        <Typography variant="subtitle1">
          We will call these POST endpoints with real-time notifications about
          your app
        </Typography>
      </Stack>

      <form onSubmit={handleSubmit}>
        <Stack
          mt={1}
          gap={2}
          direction="row"
          alignItems="baseline"
          flexDirection="column"
          justifyContent="flex-start"
        >
          <Box sx={{ width: '100%', maxWidth: 400 }}>
            <Typography variant={'body1'} fontWeight={700} mb={2}>
              Transaction Detected Webhook URL
            </Typography>

            <TextField
              type="url"
              fullWidth
              value={transactionDetectedWebhookUrl}
              label="Enter URL"
              onChange={(e) => {
                setTransactionDetectedWebhookUrl(e.target.value);
              }}
              placeholder='e.g., "https://api.example.com/webhook"'
              error={
                !isUrlValid(
                  transactionDetectedWebhookUrl,
                  defaultTransactionDetectedWebhookUrl,
                )
              }
              helperText={
                !isUrlValid(
                  transactionDetectedWebhookUrl,
                  defaultTransactionDetectedWebhookUrl,
                )
                  ? 'Please enter a valid HTTPS URL'
                  : 'This webhook is called when incoming transactions are detected to your app'
              }
            />
          </Box>

          <Box sx={{ width: '100%', maxWidth: 400 }}>
            <Typography variant={'body1'} fontWeight={700} mb={2}>
              Transaction Done Webhook URL
            </Typography>
            <TextField
              type="url"
              fullWidth
              value={transactionDoneWebhookUrl}
              label="Enter URL"
              onChange={handleWebhookUrlChange}
              placeholder='e.g., "https://api.example.com/webhook"'
              error={
                !isUrlValid(
                  transactionDoneWebhookUrl,
                  defaultTransactionDoneWebhookUrl,
                )
              }
              helperText={
                !isUrlValid(
                  transactionDoneWebhookUrl,
                  defaultTransactionDoneWebhookUrl,
                )
                  ? 'Please enter a valid HTTPS URL'
                  : "This webhook is called when transactions are 'confirmed', 'failed', 'excess' or 'incomplete'"
              }
            />
          </Box>

          <Box sx={{ width: '100%', maxWidth: 400 }}>
            <Typography variant={'body1'} fontWeight={700} mb={2}>
              Incomplete Payment Completion Webhook URL
            </Typography>

            <TextField
              type="url"
              fullWidth
              value={incompletePaymentCompletionWebhookUrl}
              label="Enter URL"
              onChange={(e) => {
                setIncompletePaymentCompletionWebhookUrl(e.target.value);
              }}
              placeholder='e.g., "https://api.example.com/webhook"'
              error={
                !isUrlValid(
                  incompletePaymentCompletionWebhookUrl,
                  defaultIncompletePaymentCompletionWebhookUrl,
                )
              }
              helperText={
                !isUrlValid(
                  incompletePaymentCompletionWebhookUrl,
                  defaultIncompletePaymentCompletionWebhookUrl,
                )
                  ? 'Please enter a valid HTTPS URL'
                  : "This webhook is called when a previously 'incomplete' payment is completed"
              }
            />
          </Box>

          <Button
            type="submit"
            variant="outlined"
            disabled={allValuesSame || urlInvalid}
          >
            Save Webhook URL
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default WebhookSection;
