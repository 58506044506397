import React, { useState } from 'react';
import { Box } from '@mui/material';

import Sidebar from '@component/Sidebar';
import Header from '@component/DashboardLayout/Header';
import MainContent from '@component/DashboardLayout/MainContent';

const DashboardLayout = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (value?: boolean) => {
    setOpen(value || !open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Header toggleDrawer={toggleDrawer} />
      <Sidebar open={open} toggleDrawer={toggleDrawer} />
      <MainContent />
    </Box>
  );
};

export default DashboardLayout;
