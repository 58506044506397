import React, { useState } from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';

import { CRYPTO_NETWORKS, MIN_WITHDRAWAL_AMOUNT } from '@variable';

import PreviouslyUsedAddresses from './PreviouslyUsedAddresses';

export const validateAddress = (
  address: string,
  cryptocurrency: string,
): boolean => {
  switch (cryptocurrency) {
    case 'BTC':
      return (
        /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(address) ||
        /^bc1[ac-hj-np-z02-9]{39,59}$/.test(address)
      );
    case 'ETH':
      return /^0x[a-fA-F0-9]{40}$/.test(address);
    case 'USDT':
    case 'USDC':
      return /^T[a-zA-Z0-9]{33}$/.test(address); // TRC20 address format
    default:
      return false;
  }
};

interface WithdrawalDialogProps {
  open: boolean;
  balance: number;
  onClose: () => void;
  cryptocurrency: string;
  onConfirm: (amount: number, address: string) => void;
  previousAddresses: string[];
}

const WithdrawalDialog: React.FC<WithdrawalDialogProps> = ({
  open,
  onClose,
  balance,
  onConfirm,
  cryptocurrency,
  previousAddresses,
}) => {
  const [amount, setAmount] = useState<number | string>('');
  const [address, setAddress] = useState<string>('');
  const [amountError, setAmountError] = useState<string | null>(null);
  const [addressError, setAddressError] = useState<string | null>(null);

  const handleConfirm = () => {
    if (validate()) onConfirm(Number(amount), address);
  };

  const validate = () => {
    let valid = true;

    if (
      !amount ||
      Number(amount) > balance ||
      Number(amount) < MIN_WITHDRAWAL_AMOUNT[cryptocurrency]
    ) {
      setAmountError('Please enter a valid amount.');
      valid = false;
    } else {
      setAmountError(null);
    }

    if (!validateAddress(address, cryptocurrency)) {
      setAddressError('Please enter a valid address.');
      valid = false;
    } else {
      setAddressError(null);
    }

    return valid;
  };

  const handleAddressSelect = (selectedAddress: string) => {
    setAddress(selectedAddress);
    setAddressError(null);
  };

  const onSetMax = () => {
    setAmount(balance);
  };

  const disableSubmitButton =
    !amount ||
    Number(amount || 0) > Number(balance) ||
    Number(amount || 0) < MIN_WITHDRAWAL_AMOUNT[cryptocurrency] ||
    balance === 0 ||
    !address ||
    !validateAddress(address, cryptocurrency);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Withdraw {cryptocurrency} ({CRYPTO_NETWORKS[cryptocurrency]})
      </DialogTitle>

      <DialogContent dividers>
        <Alert severity="info">
          <AlertTitle>
            Available Balance:{' '}
            <b>
              {balance} {cryptocurrency}
            </b>
          </AlertTitle>
          Minimum withdrawal amount: {MIN_WITHDRAWAL_AMOUNT[cryptocurrency]}{' '}
          {cryptocurrency}
        </Alert>

        <Divider variant="fullWidth" sx={{ my: 2 }} />

        <TextField
          label="Amount"
          fullWidth
          variant="outlined"
          margin="normal"
          value={amount}
          placeholder={`${balance} ${cryptocurrency} available`}
          onChange={(e) => setAmount(e.target.value)}
          error={Boolean(amountError)}
          helperText={amountError}
          InputProps={{
            endAdornment: (
              <Button variant="contained" size="small" onClick={onSetMax}>
                max
              </Button>
            ),
          }}
        />

        <TextField
          label={`${cryptocurrency} Address`}
          fullWidth
          variant="outlined"
          margin="normal"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          error={Boolean(addressError)}
          helperText={
            addressError ||
            `Only enter a ${cryptocurrency} (${CRYPTO_NETWORKS[cryptocurrency]}) network address. Entering an address from another network can lead to loss of funds`
          }
        />

        <PreviouslyUsedAddresses
          addresses={previousAddresses}
          onSelectAddress={handleAddressSelect}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={disableSubmitButton}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WithdrawalDialog;
