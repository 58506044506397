import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL, SLICE_NAME } from '@variable';
import Company, { CompanyDirector } from '@interface/company.interface';
import User from '@interface/user.interface';
import { AppTransaction } from '@interface/appTransaction.interface';
import BusinessOwnerKyc from '@interface/businessOwnerKyc.interface';

const APP_URL = `${API_URL}/company`;

export const getCompanyDetails = createAsyncThunk(
  `${SLICE_NAME}/getCompanyDetails`,
  async (): Promise<Company> => {
    const result = await axios(APP_URL);
    return result.data;
  },
);

export const getCompanyTeamMembers = createAsyncThunk(
  `${SLICE_NAME}/getCompanyTeamMembers`,
  async (): Promise<User[]> => {
    const result = await axios(`${APP_URL}/team`);
    return result.data;
  },
);

export const getCompanyTransactions = createAsyncThunk(
  `${SLICE_NAME}/getCompanyTransactions`,
  async ({
    page,
    limit,
    searchQuery,
  }: {
    page: number;
    limit: number;
    searchQuery?: string;
  }): Promise<AppTransaction> => {
    const result = await axios(
      `${APP_URL}/transactions?page=${page}&limit=${limit}&searchQuery=${searchQuery}`,
    );
    return result.data;
  },
);

export const addCompanyDirector = createAsyncThunk(
  `${SLICE_NAME}/addCompanyDirector`,
  async (data: CompanyDirector): Promise<CompanyDirector> => {
    const result = await axios.post(`${APP_URL}/director`, data);
    return result.data;
  },
);

export const addTeamMember = createAsyncThunk(
  `${SLICE_NAME}/addTeamMember`,
  async (data: User): Promise<User> => {
    const result = await axios.post(`${APP_URL}/team/member`, data);
    return result.data;
  },
);

export const updateTeamMember = createAsyncThunk(
  `${SLICE_NAME}/updateTeamMember`,
  async (data: User): Promise<User> => {
    const result = await axios.put(`${APP_URL}/team/member`, data);
    return result.data;
  },
);

export const terminateTeamMember = createAsyncThunk(
  `${SLICE_NAME}/terminateTeamMember`,
  async (id: string): Promise<User> => {
    const result = await axios.put(`${APP_URL}/team/member/terminate`, { id });
    return result.data;
  },
);

export const rehireTeamMember = createAsyncThunk(
  `${SLICE_NAME}/rehireTeamMember`,
  async (id: string): Promise<User> => {
    const result = await axios.put(`${APP_URL}/team/member/rehire`, { id });
    return result.data;
  },
);

export const updateCompanyDirector = createAsyncThunk(
  `${SLICE_NAME}/updateCompanyDirector`,
  async (data: CompanyDirector): Promise<CompanyDirector> => {
    const result = await axios.put(`${APP_URL}/director`, data);
    return result.data;
  },
);

export const deleteCompanyDirector = createAsyncThunk(
  `${SLICE_NAME}/deleteCompanyDirector`,
  async (id: string): Promise<boolean> => {
    const result = await axios.delete(`${APP_URL}/director/${id}`);
    return result.data;
  },
);

export const requestCompanyDirectorVerification = createAsyncThunk(
  `${SLICE_NAME}/requestCompanyDirectorVerification`,
  async (id: string): Promise<CompanyDirector> => {
    const result = await axios.put(
      `${APP_URL}/director/${id}/request-verification`,
    );
    return result.data;
  },
);

export const addOrUpdateBusinessOwnerKyc = createAsyncThunk(
  `${SLICE_NAME}/addOrUpdateBusinessOwnerKyc`,
  async (data: BusinessOwnerKyc): Promise<BusinessOwnerKyc> => {
    const result = await axios.put(`${APP_URL}/business-owner-kyc`, data);
    return result.data;
  },
);

export const requestBusinessOwnerVerification = createAsyncThunk(
  `${SLICE_NAME}/requestBusinessOwnerVerification`,
  async (): Promise<BusinessOwnerKyc> => {
    const result = await axios.put(`${APP_URL}/business-owner-kyc/verify`);
    return result.data;
  },
);

export const requestCompanyVerification = createAsyncThunk(
  `${SLICE_NAME}/requestCompanyVerification`,
  async (): Promise<Company> => {
    const result = await axios.put(`${APP_URL}/verify`);
    return result.data;
  },
);

export const updateCompany = createAsyncThunk(
  `${SLICE_NAME}/updateCompany`,
  async (data: Company): Promise<Company> => {
    const result = await axios.put(`${APP_URL}`, data);
    return result.data;
  },
);
