import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

import TaskItem from './TaskItem';

interface Checklist {
  companyVerificationDone: boolean;
  isRegisteredCompany: boolean;
  businessOwnerIsVerified: boolean;
  companyDirectorsVerificationDone: boolean;
}

const Checklist: FC<Checklist> = ({
  companyVerificationDone,
  businessOwnerIsVerified,
  companyDirectorsVerificationDone,
  isRegisteredCompany,
}) => {
  const isCompleted =
    (!isRegisteredCompany ||
      (companyVerificationDone && companyDirectorsVerificationDone)) &&
    businessOwnerIsVerified;

  const tasks = [
    isRegisteredCompany
      ? {
          id: 1,
          label: 'Complete Company KYC',
          isComplete: companyVerificationDone,
          link: '/dashboard/company',
        }
      : null,
    {
      id: 2,
      label: 'Complete Business Owner KYC',
      isComplete: businessOwnerIsVerified,
      link: '/dashboard/settings',
    },
    isRegisteredCompany
      ? {
          id: 3,
          label: 'Add and complete at least one company director',
          isComplete: companyDirectorsVerificationDone,
          link: '/dashboard/company',
        }
      : null,
  ].filter(Boolean);

  return (
    <Box mb={4}>
      {!isCompleted ? (
        <>
          <Typography variant="h6" gutterBottom>
            Checklist
          </Typography>
          {tasks.map((task: any) => (
            <TaskItem
              key={task.id}
              label={task.label}
              isComplete={task.isComplete}
              link={task.link}
            />
          ))}
        </>
      ) : (
        <Typography variant="h6" gutterBottom>
          We are verifying your KYC!
        </Typography>
      )}
    </Box>
  );
};

export default Checklist;
