import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { fetchAppTransactions } from '@action/app.action';
import {
  selectAppTransactionLoading,
  selectAppTransactionsByAppIdAndCurrency,
} from '@selector/app.selector';
import TransactionsTable from '@component/TransactionsTable';
import AppLoader from '@component/AppLoader';

const ViewAppTransactionHistory = () => {
  const { id, currency } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const appTransactions =
    useAppSelector((state) =>
      selectAppTransactionsByAppIdAndCurrency(state, {
        id: id as string,
        currency: currency as string,
      }),
    ) || {};

  const appTransactionLoading = useAppSelector((state) =>
    selectAppTransactionLoading(state, {
      id: id as string,
      currency: currency as string,
    }),
  );

  useEffect(() => {
    dispatch(
      fetchAppTransactions({
        page: 1,
        limit: 20,
        appId: id as string,
        currency: currency as string,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreTransactions = () => {
    dispatch(
      fetchAppTransactions({
        limit: 20,
        appId: id as string,
        currency: currency as string,
        page: appTransactions.currentPage + 1,
      }),
    );
  };

  return (
    <>
      <Button
        variant="text"
        startIcon={<ArrowBack />}
        onClick={() => navigate(-1)}
        sx={{ mb: 3 }}
      >
        Go Back
      </Button>

      {appTransactionLoading && !appTransactions.data?.length ? (
        <AppLoader />
      ) : (
        <TransactionsTable
          transactions={appTransactions.data}
          hasMore={appTransactions.hasMorePages}
          loadMoreTransactions={loadMoreTransactions}
          appTransactionLoading={appTransactionLoading}
        />
      )}
    </>
  );
};

export default ViewAppTransactionHistory;
