import { createSelector } from '@reduxjs/toolkit';

import { SLICE_NAME } from '@variable';
import { RootState } from '@store';
import BusinessOwnerKyc from '@interface/businessOwnerKyc.interface';

export const selectUser = (state: RootState) => state[SLICE_NAME].user;

export const selectGlobalLoaderIsLoading = (state: RootState) =>
  state[SLICE_NAME].isDeletingCompanyDirector ||
  state[SLICE_NAME].isRequestingCompanyDirectorVerification ||
  state[SLICE_NAME].isTerminatingTeamMember ||
  state[SLICE_NAME].isLoadingCompany ||
  state[SLICE_NAME].isLoadingApps ||
  state[SLICE_NAME].isFetchingCompanyAppTransactions ||
  state[SLICE_NAME].isCreatingCompanyDirector ||
  state[SLICE_NAME].isChangingPassword ||
  state[SLICE_NAME].isLoadingCompanyTeamMembers ||
  state[SLICE_NAME].isCreatingApp ||
  state[SLICE_NAME].isUpdatingApp ||
  state[SLICE_NAME].isUpdatingTeamMember ||
  state[SLICE_NAME].isWithdrawing ||
  state[SLICE_NAME].isRehiringTeamMember ||
  state[SLICE_NAME].isAddingTeamMember ||
  state[SLICE_NAME].isUpdatingUser ||
  state[SLICE_NAME].isUpdatingCompanyDirector ||
  state[SLICE_NAME].isUpdatingCompany ||
  state[SLICE_NAME].isRehiringTeamMember;

export const selectIsAuthenticating = (state: RootState) =>
  state[SLICE_NAME].isAuthenticating;

export const selectIsAuthenticated = (state: RootState) =>
  state[SLICE_NAME].isAuthenticated;

export const selectBusinessOwnerKyc = createSelector(
  [(state: RootState) => state[SLICE_NAME].businessOwnerKyc, selectUser],
  (businessOwnerKyc, user) => {
    return {
      ...businessOwnerKyc,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    } as BusinessOwnerKyc;
  },
);
