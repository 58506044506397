import React from 'react';

import { ROLE_ICON_MAP } from '@variable';
import UserRole from '@enum/userRole.enum';

const RoleIcon: React.FC<{
  role: UserRole;
  color: string;
}> = ({ role, color }) => {
  const IconComponent = ROLE_ICON_MAP[role];

  return <IconComponent color={color as any} />;
};

export default RoleIcon;
