import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import isEqual from 'lodash.isequal';

import TabPanel from '@component/AppDetail/TabPanel';
import CopyToClipboard from '@component/CopyToClipboard';
import WebhookSection from '@component/AppDetail/WebhookSection';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import AppCurrencies from '@component/AppDetail/AppCurrencies';
import ConfirmationDialog from '@component/ConfirmationDialog';
import {
  generateNewApiKey,
  generateNewPublicKey,
  updateApp,
} from '@action/app.action';
import { useAppDispatch } from '@hook/hooks.hook';
import { HTTPS_URL_REGEX } from '@variable';
import App from '@interface/app.interface';

interface ApiInfoProps {
  id: string;
  value: number;
  transactionDetectedWebhookUrl?: string;
  transactionDoneWebhookUrl?: string;
  incompletePaymentCompletionWebhookUrl?: string;
  apiKey?: string;
  publicKey: string;
  fiatCurrency: string;
  index: number;
  appCurrencies: CryptoCurrency[];
  whitelistedUrls: string[];
}

interface CopyableApiInfoProps {
  label: string;
  value?: string;
  id: string;
  showGenerateNewKey?: boolean;
}

const CopyableApiInfo: FC<CopyableApiInfoProps> = ({
  id,
  label,
  value,
  showGenerateNewKey,
}) => {
  const isApiKey = label === 'API Key';
  const dispatch = useAppDispatch();
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const handleGenerateNewPublicKey = () => {
    if (isApiKey) dispatch(generateNewApiKey(id));
    else dispatch(generateNewPublicKey(id));
  };

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const confirmationDialogProps = {
    open: openConfirmationDialog,
    title: `GENERATE ${isApiKey ? 'API' : 'PUBLIC'} KEY`,
    confirmButtonText: 'Generate',
    subtitle: (
      <>
        Generating a new <b>{isApiKey ? 'API' : 'PUBLIC'} KEY</b> will
        invalidate your current one. All existing integrations that use this{' '}
        <b>KEY</b>
        will stop working until you update them with the new <b>KEY</b>.
      </>
    ),
    onClose: () => {
      setOpenConfirmationDialog(false);
    },
    onConfirm: handleGenerateNewPublicKey,
  };

  return (
    <>
      <Stack direction="row" gap={2} alignItems="center">
        <Typography>
          <b>{label}:</b> {value ? `${value.substring(0, 8)}***` : `N/A`}
        </Typography>
        {value && <CopyToClipboard value={value} />}
        {showGenerateNewKey && (
          <Button
            onClick={handleOpenConfirmationDialog}
            startIcon={<RestartAltRoundedIcon />}
            size="small"
            variant="text"
          >
            {value ? `Generate new ${label}` : `Create new ${label}`}
          </Button>
        )}
      </Stack>

      {openConfirmationDialog && (
        <ConfirmationDialog {...confirmationDialogProps} />
      )}
    </>
  );
};

const ApiInfo: FC<ApiInfoProps> = ({
  id,
  value,
  publicKey,
  apiKey,
  transactionDoneWebhookUrl: defaultTransactionDoneWebhookUrl,
  transactionDetectedWebhookUrl: defaultTransactionDetectedWebhookUrl,
  incompletePaymentCompletionWebhookUrl:
    defaultIncompletePaymentCompletionWebhookUrl,
  index,
  appCurrencies,
  fiatCurrency,
  whitelistedUrls: initialWhitelistedUrls,
}) => {
  const [whitelistedUrls, setWhitelistedUrls] = useState<string[]>(
    initialWhitelistedUrls,
  );
  const [newUrl, setNewUrl] = useState<string>('');

  const copyableApiInfo = [
    {
      label: 'App ID',
      value: id,
    },
    {
      label: 'Public Key',
      value: publicKey,
      showGenerateNewKey: true,
    },
    {
      label: 'API Key',
      value: apiKey,
      showGenerateNewKey: true,
    },
  ];

  const handleAddUrl = () => {
    if (
      newUrl &&
      HTTPS_URL_REGEX.test(newUrl) &&
      !whitelistedUrls.includes(newUrl)
    ) {
      setWhitelistedUrls([...whitelistedUrls, newUrl]);
      setNewUrl('');
    }
  };

  const handleRemoveUrl = (url: string) => {
    setWhitelistedUrls(whitelistedUrls.filter((item) => item !== url));
  };
  const dispatch = useAppDispatch();
  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      await dispatch(
        updateApp({
          id,
          whitelistedUrls,
        } as App),
      );
    },
    [dispatch, id, whitelistedUrls],
  );

  const disableUrlButton = isEqual(whitelistedUrls, initialWhitelistedUrls);

  return (
    <TabPanel value={value} index={index}>
      <Stack spacing={3}>
        <Stack>
          <Typography variant="h6" fontWeight="bold" color="primary">
            Integration Information
          </Typography>
          <Typography variant="subtitle1">
            Use this information to integrate Zinari Pay into your application.
          </Typography>
        </Stack>

        {copyableApiInfo.map((apiInfo) => (
          <CopyableApiInfo {...apiInfo} key={apiInfo.label} id={id} />
        ))}
        <Divider />

        <AppCurrencies
          id={id}
          fiatCurrency={fiatCurrency}
          appCurrencies={appCurrencies}
        />

        <Divider />

        {/* Whitelisted URLs Section */}
        <Box>
          <Stack>
            <Typography variant="h6" fontWeight="bold" color="primary">
              Whitelisted URLs
            </Typography>
            <Typography variant="subtitle1">
              This URLs will be able to call your app
            </Typography>
          </Stack>

          <form onSubmit={onSubmit}>
            <Stack spacing={2} mt={2}>
              {whitelistedUrls.map((url, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  maxWidth="400px"
                  width="100%"
                >
                  <Typography>{url}</Typography>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => handleRemoveUrl(url)}
                    startIcon={<RemoveCircleOutlineRoundedIcon />}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
              <Box
                gap={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <TextField
                  name={'url'}
                  value={newUrl}
                  label="New URL"
                  sx={{ width: '100%', maxWidth: 400 }}
                  helperText={
                    newUrl && !HTTPS_URL_REGEX.test(newUrl)
                      ? 'Invalid url'
                      : 'Add the URLs that will be calling your app'
                  }
                  error={Boolean(newUrl && !HTTPS_URL_REGEX.test(newUrl))}
                  onChange={(e) => setNewUrl(e.target.value)}
                />
                <Stack direction="row" gap={4}>
                  <Button
                    variant="contained"
                    onClick={handleAddUrl}
                    disabled={
                      !HTTPS_URL_REGEX.test(newUrl) ||
                      whitelistedUrls.includes(newUrl)
                    }
                  >
                    Add URL
                  </Button>

                  {!disableUrlButton && (
                    <Button variant="contained" type="submit">
                      Update URL(s)
                    </Button>
                  )}
                </Stack>
              </Box>
            </Stack>
          </form>
        </Box>
        <Divider />

        <WebhookSection
          id={id}
          transactionDoneWebhookUrl={defaultTransactionDoneWebhookUrl}
          transactionDetectedWebhookUrl={defaultTransactionDetectedWebhookUrl}
          incompletePaymentCompletionWebhookUrl={
            defaultIncompletePaymentCompletionWebhookUrl
          }
        />
      </Stack>
    </TabPanel>
  );
};

export default ApiInfo;
