import './Spinner.scss';
import { FC } from 'react';

export enum SpinnerVariant {
  ROTATE = 'rotate',
  JUMP = 'jump',
  WAVE = 'wave',
}

export interface SpinnerProps {
  variant?: SpinnerVariant;
  color?: string;
  size?: number;
}

const Dots = ({
  size,
  className,
  backgroundColor,
}: {
  className: string;
  size?: number;
  backgroundColor?: string;
}) => (
  <div className={`spinner spinner--${className}`}>
    <div style={{ backgroundColor, height: size, width: size }}></div>
    <div style={{ backgroundColor, height: size, width: size }}></div>
    <div style={{ backgroundColor, height: size, width: size }}></div>
  </div>
);

const Spinner: FC<SpinnerProps> = ({
  variant = SpinnerVariant.ROTATE,
  color,
  size,
}: SpinnerProps) => {
  if (variant === SpinnerVariant.WAVE)
    return <Dots className="wave" backgroundColor={color} size={size} />;
  else if (variant === SpinnerVariant.JUMP)
    return <Dots className="jump" backgroundColor={color} size={size} />;
  return (
    <div
      className="spinner spinner--rotate"
      style={{
        backgroundColor: color,
        height: size,
        width: size,
        borderWidth: size && size < 20 ? 2 : 'initial',
      }}
    ></div>
  );
};

export default Spinner;
